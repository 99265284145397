import { FeeAdjustments, FeeTier, PricingRate, PricingVariants } from '../../modules/pricing/pricing.types';

export const AmountHelper = {
    findPriceVariant(variants: PricingVariants, amount: number | 'inf', sending = true): PricingRate | undefined {
        const amountType = sending ? 'sending' : 'receiving';
        const amountChecked = amount < 0 ? 0 : amount;

        return variants?.rates?.find((variant: PricingRate) => {
            const low = Number(variant.amounts[amountType].low);
            const high = variant.amounts[amountType].high === 'inf' // Check for infinity values;
                ? Infinity
                : Number(variant.amounts[amountType].high);
            return amountChecked >= low && amountChecked < high;
        });
    },

    calculateReceiving(variant: PricingRate | undefined, sendingAmount: number): number {
        return variant
            ? this.roundDown(sendingAmount * Number(variant.rate.value))
            : 0;
    },

    calculateSending(variant: PricingRate | undefined, receivingAmount: number): number {
        return variant
            ? this.roundUp(receivingAmount / Number(variant.rate.value))
            : 0;
    },

    calculateFee(amount: number | undefined, min: string, max: string, percent: string, fixedMarkup: string): string {
        const minFee = Number(min);
        const maxFee = Number(max);
        const normalizedAmount = amount || 0;

        const fee = ((Number(normalizedAmount) * Number(percent)) / 100) + Number(fixedMarkup);

        const limitMin = fee > minFee
            ? fee
            : minFee;

        const limitMax = limitMin > maxFee
            ? maxFee
            : limitMin;

        return (Math.ceil(limitMax * 1000) / 1000).toFixed(2);
    },

    calculateBestFee(variant: PricingRate | undefined, sendingAmount: number): { feeIs?: number, feeWas?: number } {
        if (variant) {
            return variant.fees.map(feeTier => {
                const wasAdjustment = (fee: FeeTier, adjustmentType: keyof FeeAdjustments): string | undefined => (
                    fee.adjustments && fee.adjustments[adjustmentType]
                        ? fee.adjustments[adjustmentType]?.was
                        : undefined
                );
                const feeIs = this.calculateFee(
                    sendingAmount,
                    feeTier.min,
                    feeTier.max,
                    feeTier.percent,
                    feeTier.fixedMarkup
                );
                const feeWas = this.calculateFee(
                    sendingAmount,
                    wasAdjustment(feeTier, 'min') || feeTier.min,
                    wasAdjustment(feeTier, 'max') || feeTier.max,
                    wasAdjustment(feeTier, 'percent') || feeTier.percent,
                    wasAdjustment(feeTier, 'fixedMarkup') || feeTier.fixedMarkup
                );
                return {
                    feeIs,
                    feeWas
                };
            }).sort((a, b) => a.feeIs - b.feeIs)[0];
        }
        return {};
    },

    sendingToReceiving(variants: PricingVariants, sendingAmount: number): number {
        const variant = this.findPriceVariant(variants, sendingAmount);
        return this.calculateReceiving(variant, sendingAmount);
    },

    receivingToSending(variants: PricingVariants, receivingAmount: number): number {
        const variant = this.findPriceVariant(variants, receivingAmount, false);
        return this.calculateSending(variant, receivingAmount);
    },

    getBetterVariant(variants: PricingVariants, sendingAmount: number): PricingRate | null {
        const currentVariant = this.findPriceVariant(variants, sendingAmount);

        if (currentVariant) {
            const betterVariant = variants?.rates?.find((item) => {
                const areFollowing = item.amounts.sending.low === currentVariant.amounts.sending.high;
                return areFollowing && currentVariant.rate.value < item.rate.value;
            });
            return betterVariant || null;
        }

        return null;
    },

    roundUp(amount: number): number {
        return Math.ceil(amount * 100) / 100;
    },

    roundDown(amount: number): number {
        return Math.floor(amount * 100) / 100;
    },

    nearestMultiplication(amount: number, multiplier: number): number {
        return Math.ceil(amount / multiplier) * multiplier;
    }
};
