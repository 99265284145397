import {
    BUTTON_CLICK_EVENT,
    CUSTOM_EVENT,
    DROPDOWN_OPTION_SELECT_EVENT,
    EventName,
    LINK_CLICK_EVENT,
    PAGE_SEEN_EVENT
} from './segment.types';
import { StringKeyedObject } from '../../types';

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
    }
}

export const SegmentHelper = {
    segment(): SegmentAnalytics.AnalyticsJS {
        return window.analytics;
    },

    track(eventName: EventName, params: StringKeyedObject): void {
        SegmentHelper.segment().track(eventName, params);
    },

    page(eventName: EventName, params: StringKeyedObject): void {
        SegmentHelper.segment().page(eventName, params);
    },

    /*
     * Track corridor view as product view
     */
    trackCorridorView(corridorId: string): void {
        const eventParams = {
            product_id: corridorId,
            content_type: 'product',
            content_ids: [corridorId],
            sku: corridorId
        };
        this.track(EventName.PRODUCT_VIEWED, eventParams);
    },

    trackButtonClick(idName: string, params: StringKeyedObject = {}): void {
        SegmentHelper.track(EventName.BUTTON_CLICK, { ...BUTTON_CLICK_EVENT, element_id: idName, ...params });
    },

    trackDropdownOptionSelect(idName: string, selected: string, params: StringKeyedObject = {}): void {
        SegmentHelper.track(EventName.DROPDOWN_OPTION_SELECT, { ...DROPDOWN_OPTION_SELECT_EVENT, dropdown_id: idName, selected, ...params });
    },

    trackCustomEvent(eventName: EventName, params: StringKeyedObject = {}): void {
        SegmentHelper.track(eventName, { ...CUSTOM_EVENT, ...params });
    },

    trackLinkClick(idName: string, params: StringKeyedObject = {}): void {
        SegmentHelper.track(EventName.LINK_CLICK, { ...LINK_CLICK_EVENT, element_id: idName, ...params });
    },

    trackPageSeen(pageName: string, params: StringKeyedObject = {}): void {
        SegmentHelper.page(EventName.PAGE_SEEN, { page_id: pageName, ...PAGE_SEEN_EVENT, ...params });
    },

    trackExperimentApplied(experimentName: string, experimentVariant: string): void {
        const propertyName = `experiment_${experimentName}`;
        SegmentHelper.track(EventName.EXPERIMENT_APPLIED, {
            [propertyName]: experimentVariant
        });
    }
};
