import {
    PricingOptions,
    PricingRate,
    PricingState,
    PricingVariants,
    PricingAdjustment
} from './pricing.types';
import { LegoState } from '../../core/store.state';

export function getInitialPricingState(): PricingState {
    return {
        pricingVariants: {},
        currentVariantId: '',
        loading: false
    };
}

export function getPricingState(state: LegoState): PricingState {
    return state?.pricing || getInitialPricingState();
}

export function getPricingLoading(state: LegoState): boolean {
    return getPricingState(state).loading;
}

export function getPricingVariants(state: LegoState): PricingVariants {
    return getPricingState(state).pricingVariants;
}

export function getPricingOptions(state: LegoState): PricingOptions | undefined {
    return getPricingVariants(state).options;
}

export function getCurrentPricingVariant(state: LegoState): PricingRate | undefined {
    const pricingState = getPricingState(state);

    return (pricingState
                && pricingState.pricingVariants
                && pricingState.currentVariantId
                && pricingState.pricingVariants.rates
                && pricingState.pricingVariants.rates
                    .find(item => item.id === pricingState.currentVariantId))
            || undefined;
}

export function getCurrentPricingVariantId(state: LegoState): string {
    const pricingState = getPricingState(state);

    return pricingState
        && pricingState.pricingVariants
        && pricingState.currentVariantId;
}

export function getPricingVariantById(state: LegoState, pricingVariantId: string): PricingRate | undefined {
    const pricingState = getPricingState(state);

    return pricingState
        && pricingState.pricingVariants
        && pricingState.pricingVariants.rates
        && pricingState.pricingVariants.rates
            .find(item => item.id === pricingVariantId);
}

export function getPricingRate(state: LegoState): string | undefined {
    const pricingStateDetails = getCurrentPricingVariant(state);
    return pricingStateDetails
        && pricingStateDetails.rate
        && pricingStateDetails.rate.value;
}

export function getRateAdjustment(state: LegoState): PricingAdjustment | undefined {
    const pricingStateDetails = getCurrentPricingVariant(state);
    return pricingStateDetails
        && pricingStateDetails.rate
        && pricingStateDetails.rate.adjustment
        && pricingStateDetails.rate.adjustment.value;
}

export function getSendingLimitLow(state: LegoState): string | undefined {
    const pricingStateDetails = getCurrentPricingVariant(state);
    return pricingStateDetails
        && pricingStateDetails.amounts
        && pricingStateDetails.amounts.sending
        && pricingStateDetails.amounts.sending.low;
}

export function getPricingTag(state: LegoState): string | undefined {
    return getPricingVariants(state).tags;
}

export function getPricingTranslatedLabel(state: LegoState): string | undefined {
    const pricingStateDetails = getCurrentPricingVariant(state);
    return pricingStateDetails
        && pricingStateDetails.promotions
        && pricingStateDetails.promotions.fee
        && pricingStateDetails.promotions.fee.label
        && pricingStateDetails.promotions.fee.label.text;
}

export function getRateTranslatedLabel(state: LegoState): string | undefined {
    const pricingStateDetails = getCurrentPricingVariant(state);
    return pricingStateDetails
        && pricingStateDetails.promotions
        && pricingStateDetails.promotions.rate
        && pricingStateDetails.promotions.rate.label
        && pricingStateDetails.promotions.rate.label.text;
}
