import {
    CalculatorConfig,
    ReceivingCountry,
    SendingCountry
} from './calculator-config.types';

declare global {
    interface Window {
        AZIMO_CALCULATOR_CONFIG: CalculatorConfig;
    }
}

export const CalculatorConfigHelper = {
    get config(): CalculatorConfig {
        return window.AZIMO_CALCULATOR_CONFIG || {};
    },

    get receivingCountries(): ReceivingCountry[] {
        return this.config.receivingCountries;
    },

    get sendingCountries(): SendingCountry[] {
        return this.config.sendingCountries;
    }
};
