import { Observable } from 'rxjs';
import { map, mergeMap, distinctUntilKeyChanged, filter, debounceTime } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { Action, ApiResponse } from '../../types';
import { legoStore } from '../../core/store';
import { HeaderNames, RequestHelper } from '../../helpers/request/request';
import { GlobalConfigHelper } from '../../helpers/global-config/global-config';
import {
    PricingActions,
    PricingActionTypes,
    PricingLoadAction,
    PricingLoadErrorAction,
    PricingLoadSuccessAction,
    PricingSetCurrentVariantIdAction
} from './pricing.actions';
import {
    getPricingConfigKey,
    isConfigLoading
} from '../calculator/calculator.state';
import { AmountHelper } from '../../helpers/amount/amount-helper';
import {
    InputCurrencyFieldBlurAction,
    SendingInputCurrencyFieldAction
} from '../currency-field/currency-field.actions';
import { getCurrentLanguage } from '../../helpers/current-language/current-language';
import { PricingVariants } from './pricing.types';

const DEFAULT_RATES_ENDPOINT = '/service-rates/v2/public/{lang}/prices/current';
const BUSINESS_RATES_ENDPOINT = '/service-rates/v2/public/{lang}/prices/current/business';

export class PricingEpics {
    loadPricing$(action$: Observable<PricingActions>): Observable<PricingActions> {
        return action$.pipe(
            ofType(PricingActionTypes.LOAD),
            mergeMap(() => this.loadConfig()),
            map(({ data }) => {
                return data
                    ? new PricingLoadSuccessAction({ pricingVariants: data })
                    : new PricingLoadErrorAction();
            })
        );
    }

    loadPricingSuccess$(action$: Observable<PricingActions>): Observable<Action> {
        return action$.pipe(
            ofType(PricingActionTypes.LOAD_SUCCESS),
            mergeMap<PricingLoadSuccessAction, Action[]>((action) => {
                const sendingAmount = legoStore.getState().calculator.sendingAmount || 0;
                const variant = AmountHelper.findPriceVariant(action.payload.pricingVariants, sendingAmount, true);
                return [
                    new SendingInputCurrencyFieldAction({ value: sendingAmount }),
                    new PricingSetCurrentVariantIdAction({ currentId: variant?.id }),
                    new InputCurrencyFieldBlurAction()
                ];
            })
        );
    }

    getRatesEndpoint(calculatorType: string): string {
        const lang = getCurrentLanguage();
        const endpoint = calculatorType === 'BUSINESS'
            ? BUSINESS_RATES_ENDPOINT
            : DEFAULT_RATES_ENDPOINT;
        return endpoint.replace('{lang}', lang);
    }

    constructor() {
        this.handleCalculatorCorridorChanges();

        legoStore.registerEpic(this.loadPricing$.bind(this));
        legoStore.registerEpic(this.loadPricingSuccess$.bind(this));
    }

    loadConfig(): Observable<ApiResponse<PricingVariants>> {
        const state = legoStore.getState().calculator;
        const calculatorType = state.calculatorType;
        const params = RequestHelper.removeNullableKeys({
            sendingCountry: state.sendingCountry,
            sendingCurrency: state.sendingCurrency,
            receivingCountry: state.payoutCountry,
            receivingCurrency: state.payoutCurrency,
            deliveryMethod: state.deliveryMethod
        });
        const headers = {
            [HeaderNames.X_APPLICATION_CALCULATOR]: calculatorType
        };
        const endpoint = `${GlobalConfigHelper.pricingApi}${this.getRatesEndpoint(calculatorType)}`;

        return RequestHelper.getRequest$(endpoint, params, headers) as Observable<ApiResponse<PricingVariants>>;
    }

    handleCalculatorCorridorChanges(): void {
        legoStore.state$
            .pipe(
                map((state) => {
                    const key = getPricingConfigKey(state);
                    const configLoading = isConfigLoading(state);
                    return {
                        key,
                        configLoading
                    };
                }),
                filter(({ key, configLoading }) => !!key && !configLoading),
                distinctUntilKeyChanged('key'),
                debounceTime(200)
            ).subscribe(() => {
                legoStore.dispatch(new PricingLoadAction());
            });
    }
}

export const pricingEpics = new PricingEpics();
