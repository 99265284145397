import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { legoStore } from '../../core/store';
import { LegoState } from '../../core/store.state';

import { SegmentHelper } from '../../helpers/segment/segmentHelper';
import {
    getPricingTrackingKey,
    isConfigLoading
} from '../calculator/calculator.state';

export class CalculatorTrackingEpics {
    debounceTime: number;

    constructor() {
        this.debounceTime = 1000;
        this.handleCalculatorCorridorChanges();
    }

    handleCalculatorCorridorChanges(): void {
        legoStore.state$
            .pipe(
                filter<LegoState>(state => !isConfigLoading(state)),
                map(getPricingTrackingKey),
                filter(Boolean),
                distinctUntilChanged(),
                debounceTime(this.debounceTime)
            )
            .subscribe((corridorId) => {
                SegmentHelper.trackCorridorView(corridorId);
            });
    }
}

new CalculatorTrackingEpics();
