import { languageMap } from '../../../server/services/language/lang-data';

export const getCurrentLanguage = (): string => {
    const defaultLanguage = 'en';
    const detectedLanguage = window.location.pathname.split('/')[1];

    return Object.keys(languageMap).includes(detectedLanguage)
        ? detectedLanguage
        : defaultLanguage;
};
