import { combineReducers } from 'redux';
import { langSwitcherReducer } from '../modules/lang-switcher/lang-switcher.reducer';
import { calculatorReducer } from '../modules/calculator/calculator.reducer';
import { countriesReducer } from '../modules/countries/countries.reducer';
import { pricingReducer } from '../modules/pricing/pricing.reducer';
import { rateAlertReducer } from '../modules/rate-alert/rate-alert.reducer';
import { partnerLogosReducer } from '../modules/partner-logos/partner-logos.reducer';

export const rootReducer = combineReducers({
    calculator: calculatorReducer,
    langSwitcher: langSwitcherReducer,
    countries: countriesReducer,
    pricing: pricingReducer,
    rateAlert: rateAlertReducer,
    partnerLogos: partnerLogosReducer
});
