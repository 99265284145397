import { CalculatorDefaultResolver } from './calculator-default-resolver';
import { PayoutCountryConfig, SendingCountryConfig } from '../corridors-config/corridors-config.types';
import { CountryCode, CurrencyCode, DeliveryMethodType } from '../../enums/types';
import { AvailablePayoutCurrency } from './calculator.types';

export class CalculatorResponseConverter {
    private readonly payoutCountryConfig: PayoutCountryConfig | undefined;

    constructor(config: SendingCountryConfig, payoutCountry: CountryCode) {
        this.payoutCountryConfig = config.payoutCountryConfigs
            .find(item => item.iso3Code === payoutCountry);
    }

    getAvailablePayoutCurrencies(): AvailablePayoutCurrency[] {
        return this.payoutCountryConfig
            ? this.payoutCountryConfig.currencies.map(currency => ({
                iso3Code: currency.iso3Code,
                availableDeliveryMethods: currency.deliveryMethods.map((deliveryMethod) => {
                    const deliveryDetails = deliveryMethod.options.details.find(details => details.type === 'ESTIMATED_DELIVERY_TIME');
                    const labelDetails = deliveryMethod.options.details.find(details => details.type === 'LOCATION_COUNT');

                    const estimatedDeliveryTime = deliveryDetails
                        ? deliveryDetails.text
                        : undefined;

                    const label = labelDetails
                        ? labelDetails.header
                        : deliveryMethod.type;

                    return {
                        type: deliveryMethod.type,
                        label,
                        estimatedDeliveryTime
                    };
                })
            }))
            : [];
    }

    getPayoutCurrency(previousCurrency: CurrencyCode, officialCurrency: CurrencyCode): CurrencyCode {
        const availablePayoutCurrencies = this.getAvailablePayoutCurrencies();

        return CalculatorDefaultResolver
            .getDefaultPayoutCurrency(availablePayoutCurrencies, previousCurrency, officialCurrency);
    }

    getDeliveryMethod(payoutCurrency: CurrencyCode, previousDeliveryMethod: DeliveryMethodType): DeliveryMethodType {
        const availablePayoutCurrencies = this.getAvailablePayoutCurrencies();
        const payoutCountry = this.payoutCountryConfig?.iso3Code;
        const availableDeliveryMethods = availablePayoutCurrencies
            .find(item => item.iso3Code === payoutCurrency)
            ?.availableDeliveryMethods || [];

        return CalculatorDefaultResolver
            .getDefaultDeliveryMethod(
                availableDeliveryMethods,
                previousDeliveryMethod,
                payoutCountry
            );
    }
}
