import { produce } from 'immer';

import { getInitialLangSwitcherState } from './lang-switcher.state';
import { LangSwitcherActions, LangSwitcherActionTypes } from './lang-switcher.actions';
import { LangSwitcherState } from './lang-switcher.types';

export const langSwitcherReducer = produce((
    draft = getInitialLangSwitcherState(),
    action: LangSwitcherActions
): LangSwitcherState => {
    switch (action.type) {
        case LangSwitcherActionTypes.CLOSE:
            return {
                ...draft,
                opened: false
            };
        case LangSwitcherActionTypes.OPEN:
            return {
                ...draft,
                opened: true
            };
        default:
            return draft;
    }
});
