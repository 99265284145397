import { SendingCountry, ReceivingCountry } from '../../helpers/calculator-config/calculator-config.types';
import { LegoState } from '../../core/store.state';
import { CountryCode, CurrencyCode } from '../../enums/types';
import { CountriesState } from './countries.types';

export function getCountriesInitialState(): CountriesState {
    return {
        sendingCountries: [],
        receivingCountries: []
    };
}

export function getCountriesState(state: LegoState): CountriesState {
    return state.countries;
}

export function getSendingCountries(state: LegoState): SendingCountry[] {
    const countriesState = getCountriesState(state);
    return countriesState?.sendingCountries;
}

export function getReceivingCountries(state: LegoState): ReceivingCountry[] {
    const countriesState = getCountriesState(state);
    return countriesState?.receivingCountries;
}

export function getOfficialCurrencyForCountry(state: LegoState, payoutCountry: CountryCode): CurrencyCode | undefined {
    const receivingCountries = getReceivingCountries(state);
    const payoutCountryData = receivingCountries
        && receivingCountries.find(item => item.iso3Code === payoutCountry);
    return payoutCountryData && payoutCountryData.currencyIso3Code;
}

export function sendingAndReceivingCountriesInState(state: CountriesState): boolean {
    return state.receivingCountries.length > 0 && state.sendingCountries.length > 0;
}
