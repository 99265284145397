import { CountryCode, CurrencyCode } from '../../enums/types';
import { CalculatorType } from '../calculator/calculator.types';
import { SendingCountryConfig } from './corridors-config.types';
import { TypedAction } from '../../types';

export enum CorridorsConfigActionTypes {
    LOAD_CONFIG = '[Corridors Config] Load config',
    LOAD_CONFIG_SUCCESS = '[Corridors Config] Load config success',
    LOAD_CONFIG_ERROR = '[Corridors Config] Load config error'
}

export class CorridorsConfigLoadConfig implements TypedAction<CorridorsConfigActionTypes> {
    readonly type = CorridorsConfigActionTypes.LOAD_CONFIG;
    constructor(public payload: {
        sendingCountry: CountryCode,
        payoutCountry: CountryCode,
        calculatorType: CalculatorType
    }) {}
}

export class CorridorsConfigLoadConfigSuccess implements TypedAction<CorridorsConfigActionTypes> {
    readonly type = CorridorsConfigActionTypes.LOAD_CONFIG_SUCCESS;

    constructor(public payload: {
        sendingCountry: CountryCode,
        payoutCountry: CountryCode,
        config: SendingCountryConfig,
        officialPayoutCurrency: CurrencyCode
    }) {}
}

export class CorridorsConfigLoadConfigError implements TypedAction<CorridorsConfigActionTypes> {
    readonly type = CorridorsConfigActionTypes.LOAD_CONFIG_ERROR;
}

export type CorridorsConfigActions =
    | CorridorsConfigLoadConfig
    | CorridorsConfigLoadConfigSuccess
    | CorridorsConfigLoadConfigError;
