import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';

import {
    LangSwitcherActions,
    LangSwitcherActionTypes,
    LangSwitcherCloseAction,
    LangSwitcherOpenAction
} from './lang-switcher.actions';
import { legoStore } from '../../core/store';
import { Action } from '../../types';

export const langSwitcherEpic: (actions$: Observable<Action>) => Observable<LangSwitcherActions> = action$ => action$.pipe(
    ofType(LangSwitcherActionTypes.TOGGLE),
    map(() => {
        const state = legoStore.getState();
        const opened = state.langSwitcher && state.langSwitcher.opened;
        return opened
            ? new LangSwitcherCloseAction()
            : new LangSwitcherOpenAction();
    })
);

legoStore.registerEpic(langSwitcherEpic);
