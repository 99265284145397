import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
    RateAlertActions,
    RateAlertActionTypes,
    RateAlertDetailsLoadAction,
    RateAlertDetailsLoadErrorAction,
    RateAlertDetailsLoadSuccessAction,
    RateAlertPricingLoadAction,
    RateAlertPricingLoadErrorAction,
    RateAlertPricingLoadSuccessAction,
    RateAlertReceivingCountriesLoadAction,
    RateAlertReceivingCountriesLoadErrorAction,
    RateAlertReceivingCountriesLoadSuccessAction,
    RateAlertSendingCountriesLoadErrorAction,
    RateAlertSendingCountriesLoadSuccessAction,
    RateAlertSubscribeAction,
    RateAlertSubscribeErrorAction,
    RateAlertSubscribeSuccessAction,
    RateAlertUnsubscribeAction,
    RateAlertUnsubscribeErrorAction,
    RateAlertUnsubscribeSuccessAction
} from './rate-alert.actions';
import { RequestHelper } from '../../helpers/request/request';
import { legoStore } from '../../core/store';
import { GlobalConfigHelper } from '../../helpers/global-config/global-config';
import { getCurrentLanguage } from '../../helpers/current-language/current-language';
import { CountryCode } from '../../enums/types';
import { StringKeyedObject } from '../../types';
import {
    RateAlertConfigResponse,
    RateAlertDetailsResponse,
    RateAlertPricingResponse,
    RateAlertSubscribeResponse,
    RateAlertUnsubscribeResponse
} from './rate-alert.types';

const COUNTRIES_CONFIG_ENDPOINT = '/service-rates/v1/public/{lang}/configurations/alerts/countries';
const PRICING_CONFIG_ENDPOINT = '/service-rates/v1/public/{lang}/configurations/alerts/corridors';
const RATE_ALERT_DETAILS_ENDPOINT = '/service-rates/v1/public/{lang}/alerts/{id}';
const RATE_ALERT_UNSUBSCRIBE_ENDPOINT = '/service-rates/v1/public/{lang}/alerts/{id}';
const SUBSCRIPTION_ENDPOINT = '/service-rates/v1/public/{lang}/alerts';

export class RateAlertEpics {
    sendingCountriesLoad$(action$: Observable<RateAlertActions>): Observable<RateAlertActions> {
        return action$
            .pipe(
                ofType(RateAlertActionTypes.SENDING_COUNTRIES_LOAD),
                mergeMap(() => this.loadCountries()),
                map(({ data: countriesConfig }) => (countriesConfig
                    ? new RateAlertSendingCountriesLoadSuccessAction(countriesConfig.sendingCountries)
                    : new RateAlertSendingCountriesLoadErrorAction()))
            );
    }

    receivingCountriesLoad$(action$: Observable<RateAlertActions>): Observable<RateAlertActions> {
        return action$
            .pipe(
                ofType(RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD),
                mergeMap<RateAlertReceivingCountriesLoadAction, Observable<RateAlertConfigResponse>>(
                    action => this.loadCountries(action.payload.sendingCountry)
                ),
                map(({ data: countriesConfig }) => (countriesConfig
                    ? new RateAlertReceivingCountriesLoadSuccessAction(countriesConfig.receivingCountries)
                    : new RateAlertReceivingCountriesLoadErrorAction()))
            );
    }

    pricingLoad$(action$: Observable<RateAlertActions>): Observable<RateAlertActions> {
        return action$
            .pipe(
                ofType(RateAlertActionTypes.PRICING_LOAD),
                mergeMap<RateAlertPricingLoadAction, Observable<RateAlertPricingResponse>>(action => this.loadPricing(action.payload)),
                map(({ data: pricing }) => (pricing
                    ? new RateAlertPricingLoadSuccessAction(pricing)
                    : new RateAlertPricingLoadErrorAction()))
            );
    }

    subscribe$(action$: Observable<RateAlertActions>): Observable<RateAlertActions> {
        return action$
            .pipe(
                ofType(RateAlertActionTypes.SUBSCRIBE),
                mergeMap<RateAlertSubscribeAction, Observable<RateAlertSubscribeResponse>>(
                    action => this.createSubscription(action.payload)
                ),
                map(({ data: alerts }) => (alerts
                    ? new RateAlertSubscribeSuccessAction(alerts)
                    : new RateAlertSubscribeErrorAction(alerts)
                ))
            );
    }

    detailsLoad$(action$: Observable<RateAlertActions>): Observable<RateAlertActions> {
        return action$
            .pipe(
                ofType(RateAlertActionTypes.RATE_ALERT_DETAILS_LOAD),
                mergeMap<RateAlertDetailsLoadAction, Observable<RateAlertDetailsResponse>>(
                    action => this.loadRateAlertDetails(action.payload)
                ),
                map(({ data, error }) => (data
                    ? new RateAlertDetailsLoadSuccessAction(data)
                    : new RateAlertDetailsLoadErrorAction(error)))
            );
    }

    unsubscribe$(action$: Observable<RateAlertActions>): Observable<RateAlertActions> {
        return action$
            .pipe(
                ofType(RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE),
                mergeMap<RateAlertUnsubscribeAction, Observable<RateAlertUnsubscribeResponse>>(
                    action => this.unsubscribeFromRateAlert(action.payload.rateAlertUuid)
                ),
                map(data => (!data.error ? new RateAlertUnsubscribeSuccessAction() : new RateAlertUnsubscribeErrorAction()))
            );
    }

    constructor() {
        legoStore.registerEpic(this.sendingCountriesLoad$.bind(this));
        legoStore.registerEpic(this.receivingCountriesLoad$.bind(this));
        legoStore.registerEpic(this.pricingLoad$.bind(this));
        legoStore.registerEpic(this.subscribe$.bind(this));
        legoStore.registerEpic(this.detailsLoad$.bind(this));
        legoStore.registerEpic(this.unsubscribe$.bind(this));
    }

    loadCountries(sendingCountry?: CountryCode): Observable<RateAlertConfigResponse> {
        const params = RequestHelper.removeNullableKeys({ sendingCountry });
        const endpoint = this.getCountriesConfigurationEndpoint();
        return RequestHelper.getRequest$(endpoint, params, {}) as Observable<RateAlertConfigResponse>;
    }

    loadPricing(params: StringKeyedObject<string>): Observable<RateAlertPricingResponse> {
        const endpoint = this.getPricingConfigurationEndpoint();
        return RequestHelper.getRequest$(endpoint, params) as Observable<RateAlertPricingResponse>;
    }

    getCountriesConfigurationEndpoint(): string {
        const lang = getCurrentLanguage();
        const countriesConfigEndpoint = COUNTRIES_CONFIG_ENDPOINT.replace('{lang}', lang);
        return `${GlobalConfigHelper.pricingApi}${countriesConfigEndpoint}`;
    }

    getPricingConfigurationEndpoint(): string {
        const lang = getCurrentLanguage();
        const pricingConfigEndpoint = PRICING_CONFIG_ENDPOINT.replace('{lang}', lang);
        return `${GlobalConfigHelper.pricingApi}${pricingConfigEndpoint}`;
    }

    getRateAlertDetailsEndpoint(rateAlertUuid: string): string {
        const lang = getCurrentLanguage();
        const detailsEndpoint = RATE_ALERT_DETAILS_ENDPOINT.replace('{lang}', lang).replace('{id}', rateAlertUuid);
        return `${GlobalConfigHelper.pricingApi}${detailsEndpoint}`;
    }

    loadRateAlertDetails(rateAlertUuid: string): Observable<RateAlertDetailsResponse> {
        const endpoint = this.getRateAlertDetailsEndpoint(rateAlertUuid);
        return RequestHelper.getRequest$(endpoint) as Observable<RateAlertDetailsResponse>;
    }

    getRateAlertUnsubscribeEndpoint(rateAlertUuid: string): string {
        const lang = getCurrentLanguage();
        const unsubscribeEndpoint = RATE_ALERT_UNSUBSCRIBE_ENDPOINT
            .replace('{lang}', lang)
            .replace('{id}', rateAlertUuid);

        return `${GlobalConfigHelper.pricingApi}${unsubscribeEndpoint}`;
    }

    unsubscribeFromRateAlert(rateAlertUuid: string): Observable<RateAlertUnsubscribeResponse> {
        const endpoint = this.getRateAlertUnsubscribeEndpoint(rateAlertUuid);
        return RequestHelper.deleteRequest$(endpoint) as Observable<RateAlertUnsubscribeResponse>;
    }

    createSubscription(params: { alerts: unknown[] }): Observable<RateAlertSubscribeResponse> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const endpoint = `${GlobalConfigHelper.pricingApi}${this.getRatesEndpoint()}`;

        return RequestHelper.postRequest$(endpoint, params, headers) as Observable<RateAlertSubscribeResponse>;
    }

    getRatesEndpoint(): string {
        const lang = getCurrentLanguage();
        return SUBSCRIPTION_ENDPOINT.replace('{lang}', lang);
    }
}

export const rateAlertEpics = new RateAlertEpics();
