import { produce } from 'immer';

import { RateAlertActions, RateAlertActionTypes } from './rate-alert.actions';
import { getRateAlertInitialState } from './rate-alert.state';
import { RateAlertState, RateCountryConfig } from './rate-alert.types';
import { CountryCode } from '../../enums/types';

const getSendingCountryConfigByCode = (
    draft: RateAlertState,
    code: CountryCode
): RateCountryConfig => draft.countries.sendingCountries.find(country => country.countryIso3Code === code)
        || draft.countries.sendingCountries.find(country => country.countryIso3Code === draft.defaultConfig.sendingCountryConfig?.countryIso3Code)
        || draft.countries.sendingCountries.sort()[0];

const getReceivingCountryConfigByCode = (
    draft: RateAlertState,
    code: CountryCode
): RateCountryConfig => draft.countries.receivingCountries.find(country => country.countryIso3Code === code)
    || draft.countries.receivingCountries.find(country => country.countryIso3Code === draft.defaultConfig.receivingCountryConfig?.countryIso3Code)
    || draft.countries.receivingCountries.sort()[0];

const areSameCurrencies = (sendingCountry?: RateCountryConfig, receivingCountry?: RateCountryConfig): boolean => (
    receivingCountry?.currencyIso3Code === sendingCountry?.currencyIso3Code
);

const calculateReceivingCountry = (draft: RateAlertState, firstReceivingCountry: RateCountryConfig): RateCountryConfig | undefined => {
    const { defaultConfig, subscription: { sendingCountryConfig, receivingCountryConfig } } = draft;
    const defaultReceivingCountry = defaultConfig.receivingCountryConfig;

    if (!areSameCurrencies(sendingCountryConfig, receivingCountryConfig)) {
        return receivingCountryConfig;
    }

    if (
        areSameCurrencies(sendingCountryConfig, receivingCountryConfig)
        && defaultReceivingCountry
        && !areSameCurrencies(sendingCountryConfig, defaultReceivingCountry)
    ) {
        return defaultReceivingCountry;
    }
    return firstReceivingCountry;
};

const calculateReceivingCountriesState = (
    draft: RateAlertState,
    receivingCountries: RateCountryConfig[]
): { receivingCountries: RateCountryConfig[], receivingCountry?: RateCountryConfig } => {
    const { sendingCountryConfig } = draft.subscription;

    const filteredReceivingCountries = receivingCountries.filter(countryConfig => !areSameCurrencies(sendingCountryConfig, countryConfig));

    return {
        receivingCountries: filteredReceivingCountries,
        receivingCountry: calculateReceivingCountry(draft, filteredReceivingCountries[0])
    };
};

export const rateAlertReducer = produce((
    draft = getRateAlertInitialState(),
    action: RateAlertActions
): RateAlertState => {
    switch (action.type) {
        case RateAlertActionTypes.SENDING_COUNTRIES_LOAD_SUCCESS:
            return {
                ...draft,
                countries: {
                    ...draft.countries,
                    sendingCountries: action.payload,
                    loading: false
                }
            };

        case RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD_SUCCESS: {
            const calculatedState = calculateReceivingCountriesState(
                draft,
                action.payload
            );

            return {
                ...draft,
                countries: {
                    ...draft.countries,
                    receivingCountries: calculatedState.receivingCountries,
                    loading: false
                },
                subscription: {
                    ...draft.subscription,
                    receivingCountryConfig: calculatedState.receivingCountry
                }
            };
        }

        case RateAlertActionTypes.PRICING_LOAD_SUCCESS:
            return {
                ...draft,
                pricing: {
                    details: action.payload,
                    loading: false
                }
            };

        case RateAlertActionTypes.SENDING_COUNTRIES_LOAD:
        case RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD:
            return {
                ...draft,
                countries: {
                    ...draft.countries,
                    loading: true
                }
            };

        case RateAlertActionTypes.PRICING_LOAD:
            return {
                ...draft,
                pricing: {
                    ...draft.pricing,
                    loading: true
                }
            };

        case RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD_ERROR:
        case RateAlertActionTypes.SENDING_COUNTRIES_LOAD_ERROR:
            return {
                ...draft,
                countries: {
                    ...draft.countries,
                    loading: false
                }
            };

        case RateAlertActionTypes.PRICING_LOAD_ERROR:
            return {
                ...draft,
                pricing: {
                    ...draft.pricing,
                    loading: true,
                    error: true
                }
            };

        case RateAlertActionTypes.SELECT_SENDING_COUNTRY:
            return {
                ...draft,
                subscription: {
                    ...draft.subscription,
                    sendingCountryConfig: {
                        ...getSendingCountryConfigByCode(draft, action.payload)
                    }
                }
            };

        case RateAlertActionTypes.SELECT_RECEIVING_COUNTRY:
            return {
                ...draft,
                subscription: {
                    ...draft.subscription,
                    receivingCountryConfig: {
                        ...getReceivingCountryConfigByCode(draft, action.payload)
                    }
                }
            };

        case RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE:
            return {
                ...draft,
                unsubscription: {
                    ...draft.unsubscription,
                    status: {
                        error: false,
                        loading: true
                    }
                }
            };

        case RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE_SUCCESS:
            return {
                ...draft,
                unsubscription: {
                    ...draft.unsubscription,
                    status: {
                        error: false,
                        loading: false
                    }
                }
            };

        case RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE_ERROR:
            return {
                ...draft,
                unsubscription: {
                    ...draft.unsubscription,
                    status: {
                        loading: false,
                        error: true
                    }
                }
            };

        case RateAlertActionTypes.RATE_ALERT_DETAILS_LOAD_SUCCESS:
            return {
                ...draft,
                unsubscription: {
                    ...draft.unsubscription,
                    details: {
                        alert: {
                            ...action.payload.alert
                        },
                        loading: false
                    }
                }
            };

        case RateAlertActionTypes.RATE_ALERT_DETAILS_LOAD_ERROR:
            return {
                ...draft,
                unsubscription: {
                    ...draft.unsubscription,
                    details: {
                        alert: undefined,
                        loading: false,
                        error: action.payload
                    }
                }
            };

        case RateAlertActionTypes.SUBSCRIBE:
            return {
                ...draft,
                subscription: {
                    ...draft.subscription,
                    loading: true
                }
            };
        case RateAlertActionTypes.SUBSCRIBE_SUCCESS:
            return {
                ...draft,
                subscription: {
                    ...draft.subscription,
                    result: action.payload,
                    loading: false
                }
            };
        case RateAlertActionTypes.SUBSCRIBE_ERROR:
            return {
                ...draft,
                subscription: {
                    ...draft.subscription,
                    error: action.payload,
                    loading: false
                }
            };

        case RateAlertActionTypes.RATE_ALERT_SET_DEFAULT_CONFIG:
            return {
                ...draft,
                defaultConfig: {
                    ...action.payload.config
                }
            };

        case RateAlertActionTypes.PRICING_LOAD_CLEAN_ERROR:
            return {
                ...draft,
                pricing: {
                    ...draft.pricing,
                    error: false
                }
            };

        default:
            return draft;
    }
});
