import { PricingVariants } from './pricing.types';
import { TypedAction } from '../../types';

export enum PricingActionTypes {
    LOAD = '[Pricing] Pricing Load',
    LOAD_SUCCESS = '[Pricing] Pricing Load Success',
    LOAD_ERROR = '[Pricing] Pricing Load Error',
    SET_CURRENT_VARIANT = '[Pricing] Set current variant id'
}

export class PricingLoadAction implements TypedAction<PricingActionTypes> {
    readonly type = PricingActionTypes.LOAD;
}

export class PricingLoadSuccessAction implements TypedAction<PricingActionTypes> {
    readonly type = PricingActionTypes.LOAD_SUCCESS;

    constructor(public payload: {
        pricingVariants: PricingVariants
    }) {}
}

export class PricingLoadErrorAction implements TypedAction<PricingActionTypes> {
    readonly type = PricingActionTypes.LOAD_ERROR;
}

export class PricingSetCurrentVariantIdAction implements TypedAction<PricingActionTypes> {
    readonly type = PricingActionTypes.SET_CURRENT_VARIANT;

    constructor(public payload: {
        currentId: string | undefined
    }) {}
}

export type PricingActions =
    PricingLoadAction
    | PricingLoadSuccessAction
    | PricingLoadErrorAction
    | PricingSetCurrentVariantIdAction;
