import { PartnerLogo, PartnerLogoState } from './partner-logos.types';

import { StringKeyedObject } from '../../types';
import { CountryCode, DeliveryMethodType } from '../../enums/types';
import { LegoState } from '../../core/store.state';

export function partnerLogoKeyFactory(payoutCountry: CountryCode, deliveryMethod: DeliveryMethodType): string {
    return `${payoutCountry}.${deliveryMethod}`;
}

export function partnerLogosStateFactory(): PartnerLogoState {
    return {
        loading: false,
        loaded: false,
        logos: []
    };
}

export function partnerLogosInitialState(): StringKeyedObject<PartnerLogoState> {
    return {};
}

export function getPartnerLogosState(state: LegoState): StringKeyedObject<PartnerLogoState> {
    return state?.partnerLogos || partnerLogosInitialState();
}

export function getPartnerLogos(key: string): (state: LegoState) => PartnerLogo[] {
    return (state: LegoState): PartnerLogo[] => {
        const partnerLogoState = getPartnerLogosState(state);
        // We limit logos to just 8 items for UI consistency and error prevention
        // Decided with Design Team
        return partnerLogoState[key]?.logos?.slice(0, 8) || [];
    };
}
