import { produce } from 'immer';

import { CountriesActions, CountriesActionTypes } from './countries.actions';
import { getCountriesInitialState } from './countries.state';
import { CountriesState } from './countries.types';

export const countriesReducer = produce((
    draft = getCountriesInitialState(),
    action: CountriesActions
): CountriesState => {
    switch (action.type) {
        case CountriesActionTypes.RECEIVING_LOAD_SUCCESS:
            return {
                ...draft,
                receivingCountries: action.payload.receivingCountries
            };
        case CountriesActionTypes.SENDING_LOAD_SUCCESS:
            return {
                ...draft,
                sendingCountries: action.payload.sendingCountries
            };
        default:
            return draft;
    }
});
