import bowser from 'bowser';

export class Browser {
    public readonly parser: bowser.Parser.Parser;

    get isIOS(): boolean {
        return this.parser.getOS().name === bowser.OS_MAP.iOS;
    }

    get isAndroid(): boolean {
        return this.parser.getOS().name === bowser.OS_MAP.Android;
    }

    get isHuawei(): boolean {
        const ua = this.window.navigator.userAgent;
        return ua.includes('HMSCore') && !ua.includes('GMS');
    }

    constructor(private readonly window: Window) {
        this.parser = bowser.getParser(window.navigator.userAgent);
    }
}

export const browser = new Browser(window);
