import 'regenerator-runtime/runtime';
import 'core-js/stable/array/find';
import 'core-js/stable/array/from';
import 'core-js/stable/array/includes';
import 'core-js/stable/array/find-index';
import 'core-js/stable/object/assign';
import 'core-js/features/promise';
import 'element-closest';
import 'default-passive-events';
import 'url-search-params-polyfill';
// import 'instant.page';
// import Bugsnag from '@bugsnag/js';
// import { PricingTracking } from 'pricing-platform-javascript-sdk';

import './core/epicsRegistry';

// import { ButtonEventTracker } from './helpers/segment/button-event-tracker';
// import { CalculatorMaster } from './modules/calculator-master/calculator-master';
// import { CAROUSELS_CONFIG } from './modules/carousels/carousel-configs';
// import { CarouselManager } from './modules/carousels/carousel-manager';
// import { DropdownEventsTracker } from './helpers/segment/dropdown-events-tracker';
// import { DynamicRateBanner } from './modules/dynamic-rate-banner/dynamic-rate-banner';
// import { GlobalConfigHelper } from './helpers/global-config/global-config';
// import { GoogleOptimizeExperiments } from './modules/google-optimize-experiments/google-optimize-experiments';
// import { Header } from './modules/header/header';
// import { HeaderHandler } from './modules/header-handler/header-handler';
// import { HeroConfetti } from './modules/hero-confetti/hero-confetti';
// import { HomepageCountryAutocomplete } from './modules/homepage-country-autocomplete/homepage-country-autocomplete';
// import { HowBusinessWorksAccordion } from './modules/how-business-works-accordion/how-business-works-accordion';
// import { HowToSendMoney } from './modules/how-to-send-money/how-to-send-money';
// import { LangSwitcher } from './modules/lang-switcher/lang-switcher';
// import { appStoreBadges } from './modules/app-store-badges/app-store-badges';
// import { MultiPricingComparison } from './modules/multi-pricing-comparison/multi-pricing-comparison';
// import { PopUp } from './modules/pop-up/pop-up';
// import { PricingComparison } from './modules/pricing-comparison/pricing-comparison';
// import { PWAHelper } from './helpers/pwa/PWAHelper';
// import { QuestionsAccordion } from './modules/questions-accordion/questions-accordion';
// import { RATE_ALERT_SUBSCRIBE_WRAPPER_CLASS } from '../server/sections/RateAlertSubscribe/rate-alert-subscribe-defaults';
// import { RATE_ALERT_UNSUBSCRIBE_WRAPPER_CLASS } from '../server/sections/RateAlertUnsubscribe/rate-alert-unsubscribe-defaults';
// import { RateAlertSubscribe } from './modules/rate-alert-subscribe/rate-alert-subscribe';
// import { RateAlertUnsubscribe } from './modules/rate-alert-unsubscribe/rate-alert-unsubscribe';
// import { ReferralLanding } from './modules/referral-landing/referral-landing';
// import { RequestHelper } from './helpers/request/request';
// import { videoModalsHandler } from './modules/video-modal/video-modals-handler';
// import { WebVitals } from './modules/web-vitals/web-vitals';
// import { PartnerLogos } from './modules/partner-logos/partner-logos';
import { translationsDebug } from './modules/translations-debug/translations-debug';
// import { ExpandingSectionsManager } from './modules/expanding-sections/expanding-sections-manager';

/**
 * Freez global configuration
 */
// GlobalConfigHelper.freeze();

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
    }
}

document.addEventListener('DOMContentLoaded', () => {
    /**
     * Initialize bugsnag
     */
    // Bugsnag.start({
    //     apiKey: GlobalConfigHelper.bugsnagKey,
    //     appVersion: GlobalConfigHelper.appVersion,
    //     metadata: GlobalConfigHelper.trackingMeta
    // });
    // Bugsnag.startSession();

    /**
     * Header - desktop and mobile, positioning, submenus, etc.
     */
    // const header = new Header(document);
    // header.init();

    /**
     * Homepage country picker
     */
    // new HomepageCountryAutocomplete(window.document);

    /**
     * Branch handling on referral landing page and claim money page
     */
    // const referralLandingHandler = new ReferralLanding(window);
    // referralLandingHandler.init();

    /**
     * Initialize pricing platform
     */
    // const trackingCli = new PricingTracking(GlobalConfigHelper.pricingApi);
    // let trackingId = trackingCli.getTrackingId();
    // trackingCli.track()
    //     .then(() => {
    //         if (!trackingId) {
    //             trackingId = trackingCli.getTrackingId();
    //             /* eslint-disable-next-line no-undef */
    //             window.analytics.identify({
    //                 pricing_tracking_id: trackingId
    //             });
    //         }
    //     });

    /**
     * Handle animations of FAQs component
     */
    // const questionsAccordionHandler = new QuestionsAccordion(document);
    // questionsAccordionHandler.init();

    /**
     * Handle header language switcher scripts
     */
    // const langSwitcher = new LangSwitcher(document);
    // langSwitcher.init();

    /**
     * Carousel manager scripts
     */
    // const carouselManager = new CarouselManager(window, CAROUSELS_CONFIG);
    // carouselManager.init();

    /**
     * Header state scripts - for logged in/out user
     */
    // const headerHandlerObj = new HeaderHandler(window.document, GlobalConfigHelper.language || 'en');
    // headerHandlerObj.init();

    /**
     * Handle expanding sections
     */
    // const expandingSectionsManager = new ExpandingSectionsManager(window);
    // expandingSectionsManager.init();

    /**
     * Handle accordion on alternative business page
     */
    // if (document.getElementById('how-business-works')) {
    //     const howBusinessWorksAccordion = new HowBusinessWorksAccordion(document);
    //     howBusinessWorksAccordion.init();
    // }

    /**
     * Handle buttons tracking in the Segment
     */
    // const buttonEventTracker = new ButtonEventTracker(window);
    // buttonEventTracker.init();

    /**
     * Handle dropdown open tracking in the Segment
     */
    // const dropdownEventsTracker = new DropdownEventsTracker(window);
    // dropdownEventsTracker.init();

    /**
     * Handle switching app-badges for proper device type
     */
    // appStoreBadges.init();

    /**
     * Create video modal
     */
    // const videoModalElement = document.getElementById('video-modal');
    // if (videoModalElement) {
    //     videoModalsHandler.register({ openerSelector: '.video-modal-opener' });
    // }

    // const heroComponent = document.querySelector('.hero-video-preview');
    // if (heroComponent) {
    //     videoModalsHandler.register({
    //         videoContainerId: 'hero-video-container',
    //         modalId: 'hero-video-modal',
    //         openerSelector: '.hero-video-trigger'
    //     });
    // }

    /**
     * Limit FAQ items on SeoAccordion on mobile
     */
    // const faqSection = document.querySelector('.faq-mobile-limit');
    // if (faqSection) {
    //     const limitTrigger = document.querySelector('.faq-mobile-limit .limit-on-mobile-trigger');
    //     limitTrigger?.addEventListener('click', (evt: Event) => {
    //         const target = evt.target as Element;
    //         target.classList.add('hidden');
    //         faqSection.classList.remove('faq-mobile-limit');
    //     });
    // }

    /**
     * Shows elements hidden until js files are loaded
     */
    /* eslint-disable no-param-reassign */
    const hiddenElements = document.querySelectorAll<HTMLElement>('[data-hide-no-js]');
    hiddenElements.forEach((el: HTMLElement) => { el.style.visibility = 'visible'; });

    /**
     * Initialize PWA event tracking
     */
    // const pwaHelper = new PWAHelper(window);
    // pwaHelper.init();

    /**
     * Handle pricing comparison component
     */
    // const pricingComparisonComponent = document.querySelectorAll('.pricing-comparison');
    // const pricingComparisonComponentV2 = document.querySelectorAll('.pricing-comparison-v2');
    // pricingComparisonComponent.forEach((componentWrapper) => {
    //     const pricingComparisonHandler = new PricingComparison(window, componentWrapper.id);
    //     pricingComparisonHandler.init();
    // });
    // pricingComparisonComponentV2.forEach((componentWrapper) => {
    //     const pricingComparisonHandler = new PricingComparison(window, componentWrapper.id);
    //     pricingComparisonHandler.init();
    // });

    /**
     * Handle Hero Confetti component
     */

    // const heroConfetti = new HeroConfetti(window, { wrapperSelector: '#hero-confetti' });
    // heroConfetti.init();

    /**
     * Handle links with keep query param
     */
    // Array.prototype.forEach.call(
    //     document.querySelectorAll('a[data-keep-query="true"]'),
    //     (elem: HTMLElement) => {
    //         const linkHref = elem?.getAttribute('href');
    //         const linkWithoutParams = linkHref?.split('?')[0];

    //         const currentQueryParams = RequestHelper.getAllUrlParams(window.location.href);
    //         const hrefQueryParams = RequestHelper.getAllUrlParams(linkHref || '');
    //         const combinedParams = {
    //             ...hrefQueryParams,
    //             ...currentQueryParams
    //         };

    //         const newQueryString = `${linkWithoutParams}${RequestHelper.generateQuery(combinedParams)}`;
    //         elem.setAttribute('href', newQueryString);
    //     }
    // );

    /**
     * Rate Alert Subscribe
     */
    // const rateAlertWrapperComponent = document.querySelector(`.${RATE_ALERT_SUBSCRIBE_WRAPPER_CLASS}`);
    // if (rateAlertWrapperComponent) {
    //     new RateAlertSubscribe(window.document);
    // }

    /**
     * How to send money component
     */
    // const howToSendMoneyComponent = document.querySelector('.how-to-send-money');
    // if (howToSendMoneyComponent) {
    //     new HowToSendMoney(window.document);
    // }

    /**
     * Unified Calculator
     */
    // const calculator = new CalculatorMaster(window.document);
    // new MultiPricingComparison(window, calculator);

    /**
     * Dynamic Rate Banner
     */
    // new DynamicRateBanner(window.document);

    /**
     * Partner logos
     */
    // new PartnerLogos(window);

    /**
     * Rate Alert Unsubscribe
     */
    // const rateAlertUnsubscribeComponent = document.querySelector(`.${RATE_ALERT_UNSUBSCRIBE_WRAPPER_CLASS}`);
    // if (rateAlertUnsubscribeComponent) {
    //     new RateAlertUnsubscribe(window.document);
    // }

    // const popUpComponent = document.querySelector('.pop-up');
    // if (popUpComponent) {
    //     new PopUp(window.document);
    // }

    /**
     * Handler for Google Optimize Experiments
     */
    // new GoogleOptimizeExperiments(window);

    /**
     * Report Web Vitals to Googla Analytics
     */
    // new WebVitals(window);

    /**
     * Translations debug mode
     */
    if (translationsDebug.isPopupMode) {
        translationsDebug.init();
    }
});
