import produce from 'immer';

import { PartnerLogosActions, PartnerLogosActionTypes } from './partner-logos.actions';
import { PartnerLogoState } from './partner-logos.types';
import {
    partnerLogoKeyFactory,
    partnerLogosInitialState,
    partnerLogosStateFactory
} from './partner-logos.state';
import { StringKeyedObject } from '../../types';

export const partnerLogosReducer = produce((
    draft = partnerLogosInitialState(),
    action: PartnerLogosActions
): StringKeyedObject<PartnerLogoState> => {
    switch (action.type) {
        case PartnerLogosActionTypes.LOAD: {
            const key = partnerLogoKeyFactory(action.payload.payoutCountry, action.payload.deliveryMethod);
            if (!draft[key]) {
                draft[key] = partnerLogosStateFactory();
            }
            draft[key].loading = true;
            return draft;
        }

        case PartnerLogosActionTypes.LOAD_SUCCESS: {
            const key = partnerLogoKeyFactory(action.payload.payoutCountry, action.payload.deliveryMethod);
            draft[key].loading = false;
            draft[key].loaded = true;
            draft[key].logos = action.payload.logos;
            return draft;
        }

        case PartnerLogosActionTypes.LOAD_ERROR: {
            const key = partnerLogoKeyFactory(action.payload.payoutCountry, action.payload.deliveryMethod);
            draft[key].loading = false;
            return draft;
        }

        default:
            return draft;
    }
});
