import { Observable, of } from 'rxjs';
import { map, mergeMap, tap, filter, distinctUntilChanged } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {
    CorridorsConfigLoadConfigSuccess,
    CorridorsConfigLoadConfigError,
    CorridorsConfigActionTypes, CorridorsConfigLoadConfig, CorridorsConfigActions
} from './corridors-config.actions';
import { legoStore } from '../../core/store';
import { RequestHelper } from '../../helpers/request/request';
import { GlobalConfigHelper } from '../../helpers/global-config/global-config';
import { getOfficialCurrencyForCountry } from '../countries/countries.state';
import { getCorridorConfigKey } from '../calculator/calculator.state';
import { getCurrentLanguage } from '../../helpers/current-language/current-language';
import { Action, StringKeyedObject } from '../../types';
import { SendingCountryConfigResponse } from './corridors-config.types';
import { CountryCode, CurrencyCode } from '../../enums/types';
import { CalculatorType } from '../calculator/calculator.types';

export class CorridorsConfigEpics {
    private cachedResponses: StringKeyedObject<SendingCountryConfigResponse> = {};

    loadConfig$(action$: Observable<Action>): Observable<CorridorsConfigActions> {
        return action$.pipe(
            ofType(CorridorsConfigActionTypes.LOAD_CONFIG),
            mergeMap<CorridorsConfigLoadConfig, Observable<SendingCountryConfigResponse>>(
                ({ payload }) => this.loadConfig(
                    payload.sendingCountry,
                    payload.payoutCountry,
                    payload.calculatorType
                )
            ),
            map((response) => {
                const officialPayoutCurrency = getOfficialCurrencyForCountry(
                    legoStore.getState(),
                    response.payoutCountry as CountryCode
                );
                return response.data
                    ? new CorridorsConfigLoadConfigSuccess({
                        config: response.data.sendingCountryConfigs.items[0],
                        sendingCountry: response.sendingCountry as CountryCode,
                        payoutCountry: response.payoutCountry as CountryCode,
                        officialPayoutCurrency: officialPayoutCurrency as CurrencyCode
                    })
                    : new CorridorsConfigLoadConfigError();
            })
        );
    }

    constructor() {
        legoStore.registerEpic(this.loadConfig$.bind(this));
        this.handleCalculatorCorridorChanges();
    }

    getConfigUrl(
        sendingCountry: CountryCode,
        calculatorType: CalculatorType
    ): string {
        const URLS_MAP = {
            INDIVIDUAL: '/sendingCountryConfigs/',
            BUSINESS: '/business/sendingCountryConfigs/'
        };
        const lang = getCurrentLanguage();
        const configEndpoint = URLS_MAP[calculatorType];
        const baseSendingConfigUrl = `${GlobalConfigHelper.coreApi}/${lang}/rest${configEndpoint}`;
        return `${baseSendingConfigUrl}${sendingCountry}`;
    }

    loadConfig(
        sendingCountry: CountryCode,
        payoutCountry: CountryCode,
        calculatorType: CalculatorType = 'INDIVIDUAL'
    ): Observable<SendingCountryConfigResponse> {
        const cacheKey = `${sendingCountry}.${payoutCountry}.${calculatorType}`;
        const cached = this.cachedResponses[cacheKey];

        if (cached) {
            return of({
                ...cached,
                sendingCountry,
                payoutCountry
            });
        }

        const endpoint = this.getConfigUrl(sendingCountry, calculatorType);
        return RequestHelper.getRequest$(endpoint, { payoutCountryIso3Code: payoutCountry })
            .pipe(
                map(res => res as SendingCountryConfigResponse),
                tap((res: SendingCountryConfigResponse) => {
                    if (res.data && res.data.sendingCountryConfigs) {
                        this.cachedResponses[cacheKey] = res;
                    }
                }),
                map((res: SendingCountryConfigResponse) => ({ ...res, sendingCountry, payoutCountry }))
            );
    }

    handleCalculatorCorridorChanges(): void {
        legoStore.state$
            .pipe(
                map(getCorridorConfigKey),
                filter(key => !!key),
                distinctUntilChanged()
            )
            .subscribe(() => {
                const { sendingCountry, payoutCountry, calculatorType } = legoStore.getState().calculator;

                if (sendingCountry && payoutCountry && calculatorType) {
                    legoStore.dispatch(new CorridorsConfigLoadConfig({ sendingCountry, payoutCountry, calculatorType }));
                }
            });
    }
}

export const corridorsConfigEpics = new CorridorsConfigEpics();
