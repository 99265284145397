import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const DEBUG_TRANSLATION_ATTR_NAME = 'data-translations-key';
const DEBUG_TRANSLATION_TOOLTIP_ID = 'translation-key-tooltip';

export class TranslationsDebug {
    onTranslation$: Subscription;
    document: Document;
    tooltipElem: HTMLElement;

    get isPopupMode(): boolean {
        return !!this.document.querySelector('[data-translations-key]');
    }

    get isInlineMode(): boolean {
        return !!this.document.querySelector('[data-translations-inline]');
    }

    constructor(private readonly window: Window) {
        this.document = window.document;
    }

    init(): void {
        this.tooltipElem = this.createTooltipElement();
        this.document.body.appendChild(this.tooltipElem);
        this.initHoverEvent();
    }

    private initHoverEvent(): void {
        this.onTranslation$ = fromEvent<MouseEvent>(this.document, 'mouseover')
            .pipe(
                filter((evt: MouseEvent) => {
                    const el = evt.target as HTMLElement;
                    return el.hasAttribute(DEBUG_TRANSLATION_ATTR_NAME);
                })
            ).subscribe(evt => {
                const el = evt.target as HTMLElement;
                const rect = el.getBoundingClientRect();
                const currentTranslationKey = el.getAttribute(DEBUG_TRANSLATION_ATTR_NAME) as string;
                this.updateTooltip(currentTranslationKey, rect.top, rect.left);
            });
    }

    private createTooltipElement(): HTMLElement {
        const tooltipElem = this.document.createElement('a');
        tooltipElem.id = DEBUG_TRANSLATION_TOOLTIP_ID;
        tooltipElem.setAttribute('target', '_blank');
        tooltipElem.setAttribute('title', 'Open in Lokalise');
        return tooltipElem;
    }

    private updateTooltip(currentKey, hoveredTop: number, hoveredLeft: number): void {
        const lokaliseLink = `https://app.lokalise.com/project/267870576023b59f99f1a2.46978806/?view=multi&search=${currentKey}&branch=master`;
        this.tooltipElem.style.top = `${hoveredTop + this.window.scrollY - 35}px`;
        this.tooltipElem.style.left = `${hoveredLeft}px`;
        this.tooltipElem.innerText = currentKey;
        this.tooltipElem.setAttribute('href', lokaliseLink);
    }
}

export const translationsDebug = new TranslationsDebug(window);
