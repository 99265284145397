import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';

import { legoStore } from '../../core/store';
import {
    ReceivingCountriesLoadSuccessAction,
    CountriesActionTypes,
    SendingCountriesLoadSuccessAction, CountriesActions
} from './countries.actions';
import { CalculatorConfigHelper } from '../../helpers/calculator-config/calculator-config';
import { Action } from '../../types';

export class CountriesEpics {
    static sendingLoad$(action$: Observable<Action>): Observable<CountriesActions> {
        return action$
            .pipe(
                ofType(CountriesActionTypes.SENDING_LOAD),
                /**
                 * it's handled by an action in case, we want to
                 * move re-implement it to be downloaded asynchronously (XHR)
                 */
                map(() => {
                    const sendingCountries = CalculatorConfigHelper.sendingCountries;
                    return new SendingCountriesLoadSuccessAction({ sendingCountries });
                })
            );
    }

    static receivingLoad$(action$: Observable<Action>): Observable<CountriesActions> {
        return action$
            .pipe(
                ofType(CountriesActionTypes.RECEIVING_LOAD),
                map(() => {
                    const receivingCountries = CalculatorConfigHelper.receivingCountries;
                    return new ReceivingCountriesLoadSuccessAction({ receivingCountries });
                })
            );
    }

    constructor() {
        legoStore.registerEpic(CountriesEpics.receivingLoad$);
        legoStore.registerEpic(CountriesEpics.sendingLoad$);
    }
}

export const countriesEpics = new CountriesEpics();
