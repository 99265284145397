import { Observable } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, map, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { legoStore } from '../../core/store';
import { RequestHelper } from '../../helpers/request/request';

import { getCalculatorState } from '../calculator/calculator.state';
import {
    PartnerLogosActions,
    PartnerLogosActionTypes,
    PartnerLogosLoadAction,
    PartnerLogosLoadErrorAction,
    PartnerLogosLoadSuccessAction
} from './partner-logos.actions';
import { PartnerLogoResponseWithCorridor } from './partner-logos.types';
import { CountryCode, DeliveryMethodType } from '../../enums/types';
import { partnerLogoKeyFactory } from './partner-logos.state';
import { GlobalConfigHelper } from '../../helpers/global-config/global-config';

export class PartnerLogosEpics {
    loadLogos$(action$: Observable<PartnerLogosActions>): Observable<PartnerLogosActions> {
        return action$.pipe(
            ofType(PartnerLogosActionTypes.LOAD),
            mergeMap(({ payload: { payoutCountry, deliveryMethod } }) => this.loadPartnerLogos(payoutCountry, deliveryMethod)),
            map(({ response: { data }, payoutCountry, deliveryMethod }) => {
                return data
                    ? new PartnerLogosLoadSuccessAction({
                        logos: data.content.logos,
                        payoutCountry,
                        deliveryMethod
                    })
                    : new PartnerLogosLoadErrorAction({
                        payoutCountry,
                        deliveryMethod
                    });
            })
        );
    }

    constructor() {
        this.handleCalculatorCorridorChanges();
        legoStore.registerEpic(this.loadLogos$.bind(this));
    }

    private loadPartnerLogos(
        payoutCountry: CountryCode,
        deliveryMethod: DeliveryMethodType
    ): Observable<PartnerLogoResponseWithCorridor> {
        const baseUrl = GlobalConfigHelper.pricingApi;
        const language = GlobalConfigHelper.language;
        const endpoint = `${baseUrl}/service-content/public/v1/${language}/use-cases/partner-logos/${payoutCountry}/${deliveryMethod}`;
        return RequestHelper.getRequest$(endpoint)
            .pipe(
                map(response => ({ response, payoutCountry, deliveryMethod }))
            ) as Observable<PartnerLogoResponseWithCorridor>;
    }

    handleCalculatorCorridorChanges(): void {
        legoStore.state$
            .pipe(
                map((state) => {
                    const payoutCountry = getCalculatorState(state).payoutCountry;
                    const deliveryMethod = getCalculatorState(state).deliveryMethod;
                    const key = partnerLogoKeyFactory(payoutCountry as CountryCode, deliveryMethod as DeliveryMethodType);
                    return {
                        payoutCountry,
                        deliveryMethod,
                        key
                    };
                }),
                distinctUntilKeyChanged('key'),
                debounceTime(200)
            ).subscribe(({ payoutCountry, deliveryMethod }) => {
                if (!!payoutCountry && !!deliveryMethod) {
                    legoStore.dispatch(new PartnerLogosLoadAction({ payoutCountry, deliveryMethod }));
                }
            });
    }
}

export const partnerLogosEpics = new PartnerLogosEpics();
