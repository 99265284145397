export const languageMap = {
    de: {
        shortCode: 'de',
        order: 30,
        locale: 'de_DE',
        code: 'DEU',
        name: 'Deutsch',
        langSwitchUrl: '/de',
        regions: ['de', 'at', 'ch', 'es', 'fr', 'gb', 'pl']
    },
    en: {
        shortCode: 'en',
        order: 10,
        locale: 'en_US',
        code: 'GBR',
        name: 'English',
        langSwitchUrl: '/en',
        regions: ['au', 'gb', 'ca', 'de', 'es', 'nl', 'ie', 'se', 'fi', 'fr', 'pl', 'be', 'no', 'it', 'dk', 'at', 'pt', 'ch', 'hk']
    },
    es: {
        shortCode: 'es',
        order: 60,
        locale: 'es_ES',
        code: 'ESP',
        name: 'Español',
        langSwitchUrl: '/es',
        associatedDomain: 'azimo.es',
        regions: ['es', 'de', 'fr', 'gb', 'ca', 'nl', 'it', 'au']
    },
    fr: {
        shortCode: 'fr',
        order: 20,
        locale: 'fr_FR',
        code: 'FRA',
        name: 'Français',
        langSwitchUrl: '/fr',
        associatedDomain: 'azimo.fr',
        regions: ['fr', 'be', 'ca', 'de', 'gb', 'es', 'it', 'ch', 'au']
    },
    it: {
        shortCode: 'it',
        order: 40,
        locale: 'it_IT',
        code: 'ITA',
        name: 'Italiano',
        langSwitchUrl: '/it',
        regions: ['it']
    },
    pl: {
        shortCode: 'pl',
        order: 70,
        locale: 'pl_PL',
        code: 'POL',
        name: 'Polski',
        langSwitchUrl: '/pl',
        regions: ['pl', 'gb', 'dk', 'de', 'fr']
    },
    pt: {
        shortCode: 'pt',
        order: 80,
        locale: 'pt_PT',
        code: 'PRT',
        name: 'Português',
        langSwitchUrl: '/pt',
        regions: ['pt', 'gb', 'de', 'fr', 'es', 'ca', 'au']
    },
    ro: {
        shortCode: 'ro',
        order: 90,
        locale: 'ro_RO',
        code: 'ROU',
        name: 'Română',
        langSwitchUrl: '/ro',
        regions: ['gb', 'de', 'nl']
    },
    ru: {
        shortCode: 'ru',
        order: 50,
        locale: 'ru_RU',
        code: 'RUS',
        name: 'Русский',
        langSwitchUrl: '/ru',
        regions: ['de', 'pl', 'gb']
    },
    zh: {
        shortCode: 'zh',
        order: 50,
        locale: 'zh_CN',
        code: 'CHN',
        name: '中文版',
        langSwitchUrl: '/zh',
        regions: ['hk', 'de', 'au', 'ca', 'es', 'fr', 'it']
    }
};
