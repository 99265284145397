import { ReceivingCountry, SendingCountry } from '../../helpers/calculator-config/calculator-config.types';

export enum CountriesActionTypes {
    SENDING_LOAD = '[Countries] Sending Countries Load',
    SENDING_LOAD_SUCCESS = '[Countries] Sending Countries Load Success',
    RECEIVING_LOAD = '[Countries] Receiving Countries Load',
    RECEIVING_LOAD_SUCCESS = '[Countries] Receiving Countries Load Success'
}

export class SendingCountriesLoadAction {
    readonly type = CountriesActionTypes.SENDING_LOAD;
}

export class SendingCountriesLoadSuccessAction {
    readonly type = CountriesActionTypes.SENDING_LOAD_SUCCESS;

    constructor(public payload: {
        sendingCountries: SendingCountry[]
    }) {}
}

export class ReceivingCountriesLoadAction {
    readonly type = CountriesActionTypes.RECEIVING_LOAD;
}

export class ReceivingCountriesLoadSuccessAction {
    readonly type = CountriesActionTypes.RECEIVING_LOAD_SUCCESS;

    constructor(public payload: {
        receivingCountries: ReceivingCountry[]
    }) {}
}

export type CountriesActions =
    | SendingCountriesLoadAction
    | SendingCountriesLoadSuccessAction
    | ReceivingCountriesLoadAction
    | ReceivingCountriesLoadSuccessAction;
