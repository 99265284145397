import { produce } from 'immer';

import { getInitialCalculatorState } from './calculator.state';
import { CorridorsConfigActions, CorridorsConfigActionTypes } from '../corridors-config/corridors-config.actions';
import { CalculatorResponseConverter } from './calculator-response-converter';
import { CalculatorActions, CalculatorActionTypes } from './calculator.actions';
import {
    CurrencyFieldActions,
    CurrencyFieldActionTypes
} from '../currency-field/currency-field.actions';
import { CalculatorDefaultResolver } from './calculator-default-resolver';
import { CalculatorState } from './calculator.types';
import {
    ReceivingCountryPickerActions,
    ReceivingCountryPickerActionTypes
} from '../receiving-country-picker/receiving-country-picker.actions';

export const calculatorReducer = produce((
    draft = getInitialCalculatorState(),
    action: CalculatorActions
        | CorridorsConfigActions
        | CurrencyFieldActions
        | ReceivingCountryPickerActions
): CalculatorState => {
    switch (action.type) {
        case CorridorsConfigActionTypes.LOAD_CONFIG: {
            return {
                ...draft,
                configLoading: true,
                availablePayoutCurrencies: []
            };
        }

        case CorridorsConfigActionTypes.LOAD_CONFIG_SUCCESS: {
            const payoutCountry = action.payload.payoutCountry;
            const config = action.payload.config;
            const sendingCountry = config.iso3Code;
            const sendingCurrency = config.currencyIso3Code;

            const calculatorResponseConverter = new CalculatorResponseConverter(config, payoutCountry);
            const payoutCurrencies = calculatorResponseConverter
                .getAvailablePayoutCurrencies();
            const payoutCurrency = calculatorResponseConverter
                .getPayoutCurrency(draft.payoutCurrency, action.payload.officialPayoutCurrency);
            const deliveryMethod = calculatorResponseConverter
                .getDeliveryMethod(payoutCurrency, draft.deliveryMethod);

            return {
                ...draft,
                availablePayoutCurrencies: payoutCurrencies,
                corridorConfigLoadingError: false,
                sendingCountry,
                sendingCurrency,
                payoutCountry,
                payoutCurrency,
                deliveryMethod,
                configLoading: false
            };
        }
        case CorridorsConfigActionTypes.LOAD_CONFIG_ERROR: {
            return {
                ...draft,
                corridorConfigLoadingError: true,
                configLoading: false
            };
        }

        case CalculatorActionTypes.SET_PAYOUT_CURRENCY: {
            const payoutCurrency = action.payload.payoutCurrency;
            const currentPayoutConfig = draft.availablePayoutCurrencies
                .find(item => item.iso3Code === payoutCurrency);
            const payoutCountry = draft.payoutCountry;
            let deliveryMethod = draft.deliveryMethod;

            if (currentPayoutConfig && currentPayoutConfig.availableDeliveryMethods) {
                deliveryMethod = CalculatorDefaultResolver.getDefaultDeliveryMethod(
                    currentPayoutConfig.availableDeliveryMethods,
                    draft.deliveryMethod,
                    payoutCountry
                );
            }

            return {
                ...draft,
                payoutCurrency: action.payload.payoutCurrency,
                deliveryMethod
            };
        }

        case CalculatorActionTypes.SET_CALCULATOR_TYPE: {
            return {
                ...draft,
                calculatorType: action.payload.type
            };
        }

        case CalculatorActionTypes.SET_SENDING_CURRENCY: {
            return {
                ...draft,
                sendingCurrency: action.payload.currencyCode
            };
        }

        case CalculatorActionTypes.SET_DELIVERY_METHOD:
            return {
                ...draft,
                deliveryMethod: action.payload.deliveryMethod
            };

        case CalculatorActionTypes.SET_SENDING_AMOUNT:
            return {
                ...draft,
                sendingAmount: action.payload.value
            };
        case CurrencyFieldActionTypes.SENDING_INPUT:
            return {
                ...draft,
                sendingAmount: action.payload.value
            };

        case CalculatorActionTypes.SET_RECEIVING_AMOUNT:
        case CurrencyFieldActionTypes.RECEIVING_INPUT:
            return {
                ...draft,
                receivingAmount: action.payload.value
            };

        case ReceivingCountryPickerActionTypes.SELECT:
            return {
                ...draft,
                payoutCountry: action.payload.countryCode
            };

        default:
            return draft;
    }
});
