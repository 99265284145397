import { CountryConfig, CurrencyConfig, GlobalConfig, TrackingMeta } from './global-config.types';
import { StringKeyedObject } from '../../types';
import { translationsDebug } from '../../modules/translations-debug/translations-debug';

declare global {
    interface Window {
        AZIMO_CONFIG: GlobalConfig
    }
}

export const GlobalConfigHelper = {
    get config(): Partial<GlobalConfig> { return window.AZIMO_CONFIG || {}; },

    get apiVersion(): string { return this.config.X_API_VERSION; },
    get appVersion(): string { return this.config.appVersion; },
    get bugsnagKey(): string { return this.config.bugsnagKey; },
    get coreApi(): string { return this.config.coreApi; },
    get countries(): CountryConfig[] { return this.config.countries; },
    get currencies(): StringKeyedObject<CurrencyConfig> { return this.config.currencies; },
    get developmentMode(): boolean { return this.config.developmentMode; },
    get language(): string { return this.config.language; },
    get oneTrustDomainScript(): string { return this.config.oneTrustDomainScript; },
    get platformVersion(): string { return this.config.platformVersion; },
    get pricingApi(): string { return this.config.pricingApi; },
    get trackingMeta(): TrackingMeta { return this.config.trackingMeta; },
    get translations(): StringKeyedObject<string> { return this.config.translations; },

    translate(key: string): string {
        if (translationsDebug.isInlineMode) {
            return key;
        }
        return (this.translations && this.translations[key]) || key;
    },

    freeze(): void {
        Object.freeze(this.config);
    }
};
