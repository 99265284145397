import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { Action } from 'redux';

import { legoStore } from '../../core/store';
import {
    CalculatorActions,
    CalculatorActionTypes,
    CalculatorSetReceivingAmount,
    CalculatorSetSendingAmount,
    CalculatorSetSendingCurrencyAction
} from './calculator.actions';
import {
    ApplyAmountMultiplierAction,
    CurrencyFieldActions,
    CurrencyFieldActionTypes,
    InputCurrencyFieldBlurAction,
    ReceivingInputCurrencyFieldAction,
    SendingInputCurrencyFieldAction
} from '../currency-field/currency-field.actions';
import { AmountHelper } from '../../helpers/amount/amount-helper';
import { getPricingOptions, getPricingVariants } from '../pricing/pricing.state';
import { CorridorsConfigLoadConfig } from '../corridors-config/corridors-config.actions';
import { PricingActions, PricingSetCurrentVariantIdAction } from '../pricing/pricing.actions';
import { getReceivingAmount } from './calculator.state';

export class CalculatorEpics {
    static setSendingCurrency$(action$: Observable<Action>): Observable<CorridorsConfigLoadConfig> {
        return action$.pipe(
            ofType(CalculatorActionTypes.SET_SENDING_CURRENCY),
            mergeMap<CalculatorSetSendingCurrencyAction, CorridorsConfigLoadConfig[]>(({ payload }) => {
                const state = legoStore.getState();
                const sendingCountry = payload.countryCode;
                const calculatorType = state.calculator.calculatorType;
                if (state.calculator && state.calculator.payoutCountry) {
                    const payoutCountry = state.calculator.payoutCountry;
                    return [new CorridorsConfigLoadConfig({ sendingCountry, payoutCountry, calculatorType })];
                }
                return [];
            })
        );
    }

    static sendingAmountInput$(action$: Observable<CurrencyFieldActions>): Observable<CalculatorActions | PricingActions> {
        return action$.pipe(
            ofType(CurrencyFieldActionTypes.SENDING_INPUT),
            mergeMap<SendingInputCurrencyFieldAction, Array<CalculatorActions | PricingActions>>(({ payload }) => {
                if (Number.isNaN(payload.value)) {
                    return [new CalculatorSetReceivingAmount({ value: 0 })];
                }
                const pricingVariants = getPricingVariants(legoStore.getState());
                const sendingAmount = payload.value;
                const newVariant = AmountHelper.findPriceVariant(pricingVariants, sendingAmount);
                const variantId = newVariant && newVariant.id ? newVariant.id : '';
                const receivingAmount = AmountHelper.calculateReceiving(newVariant, sendingAmount);
                return [
                    new CalculatorSetReceivingAmount({ value: receivingAmount }),
                    new PricingSetCurrentVariantIdAction({ currentId: variantId })
                ];
            })
        );
    }

    static receivingAmountInput$(action$: Observable<CurrencyFieldActions>): Observable<CalculatorActions | PricingActions> {
        return action$.pipe(
            ofType(CurrencyFieldActionTypes.RECEIVING_INPUT),
            mergeMap<ReceivingInputCurrencyFieldAction, Array<CalculatorActions | PricingActions>>(({ payload }) => {
                if (Number.isNaN(payload.value)) {
                    return [new CalculatorSetSendingAmount({ value: 0 })];
                }
                const pricingVariants = getPricingVariants(legoStore.getState());
                const receivingAmount = payload.value;
                const newVariant = AmountHelper.findPriceVariant(pricingVariants, receivingAmount, false);
                const variantId = newVariant && newVariant.id ? newVariant.id : '';
                const sendingAmount = AmountHelper.calculateSending(newVariant, receivingAmount);
                return [
                    new CalculatorSetSendingAmount({ value: sendingAmount }),
                    new PricingSetCurrentVariantIdAction({ currentId: variantId })
                ];
            })
        );
    }

    static amountInputBlur$(action$: Observable<CurrencyFieldActions>): Observable<ReceivingInputCurrencyFieldAction> {
        return action$.pipe(
            ofType(
                CurrencyFieldActionTypes.INPUT_BLUR,
                CurrencyFieldActionTypes.APPLY_AMOUNT_MULTIPLIER
            ),
            mergeMap<InputCurrencyFieldBlurAction | ApplyAmountMultiplierAction, ReceivingInputCurrencyFieldAction[]>(() => {
                const state = legoStore.getState();
                const pricingOptions = getPricingOptions(state);
                const multiplier = pricingOptions
                    && pricingOptions.receivingAmount
                    && pricingOptions.receivingAmount.multiplier;

                const receivingAmount = getReceivingAmount(state);

                if (multiplier && receivingAmount) {
                    const newReceivingAmount = AmountHelper.nearestMultiplication(receivingAmount, multiplier);
                    return [
                        new ReceivingInputCurrencyFieldAction({ value: newReceivingAmount })
                    ];
                }
                return [];
            })
        );
    }

    constructor() {
        legoStore.registerEpic(CalculatorEpics.setSendingCurrency$);
        legoStore.registerEpic(CalculatorEpics.sendingAmountInput$);
        legoStore.registerEpic(CalculatorEpics.receivingAmountInput$);
        legoStore.registerEpic(CalculatorEpics.amountInputBlur$);
    }
}

new CalculatorEpics();
