import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Action, applyMiddleware, createStore, Dispatch, Store, StoreEnhancer } from 'redux';
import { combineEpics, createEpicMiddleware, Epic, EpicMiddleware } from 'redux-observable';

import { rootReducer } from './rootReducer';
import { initialState, LegoState } from './store.state';
import { GlobalConfigHelper } from '../helpers/global-config/global-config';

export class LegoStore {
    devToolsEnabled: boolean;
    store: Store<LegoState, Action>;
    stateStream$: ReplaySubject<LegoState>;
    epicMiddleware: EpicMiddleware<Action, Action, LegoState>;
    epics$: BehaviorSubject<Epic<Action, Action, LegoState>>;
    rootEpic: Epic<Action, Action, LegoState>;

    get state$(): Observable<LegoState> {
        return this.stateStream$.pipe(map(() => this.getState()));
    }

    constructor() {
        this.devToolsEnabled = GlobalConfigHelper.developmentMode;
        this.init();
    }

    dispatch(action: Action): void {
        this.store.dispatch(action);
    }

    getState(): LegoState {
        return this.store.getState();
    }

    registerEpic(epic: Epic<Action, Action, LegoState>): void {
        this.epics$.next(epic);
    }

    init(): void {
        this.store = {} as unknown as Store<LegoState, Action>;
        this.setRootEpic();
        this.setStore();
        this.assignStoreStateStream();
    }

    private assignStoreStateStream(): void {
        this.stateStream$ = new ReplaySubject(1);
        this.stateStream$.next(this.getState());
        this.store.subscribe(() => {
            this.stateStream$.next(this.store.getState());
        });
    }

    private setStore(): void {
        this.store = createStore(
            rootReducer,
            initialState,
            this.getEnhancer()
        );
        this.patchDispatch();
        this.epicMiddleware.run(this.rootEpic);
    }

    private patchDispatch(): void {
        const next = this.store.dispatch;
        this.store.dispatch = ((action: Action): void => {
            const plainAction = Object.assign({}, action);
            next(plainAction);
        }) as Dispatch<Action>;
    }

    private getEnhancer(): StoreEnhancer {
        return this.devToolsEnabled
            ? composeWithDevTools(
                applyMiddleware(this.epicMiddleware)
            )
            : applyMiddleware(this.epicMiddleware);
    }

    private setRootEpic(): void {
        this.epicMiddleware = createEpicMiddleware();
        this.epics$ = new BehaviorSubject(combineEpics());
        this.rootEpic = (action$, state$): Observable<Action> => this.epics$
            .pipe(
                mergeMap(epic => epic(action$, state$, {})
                    .pipe(
                        // casting to plain objects to avoid errors
                        map(output => Object.assign({}, output))
                    ))
            );
    }
}

export const legoStore = new LegoStore();
