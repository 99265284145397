/* eslint-disable prefer-promise-reject-errors,no-bitwise,no-mixed-operators */
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import Promise from 'promise-polyfill';
import { PricingTracking } from 'pricing-platform-javascript-sdk';
import { GlobalConfigHelper } from '../global-config/global-config';
import { StringKeyedObject } from '../../types';
import { translationsDebug } from '../../modules/translations-debug/translations-debug';

export const HeaderNames = {
    X_AZIMO_UTDM: 'X-Azimo-UTDM',
    X_API_VERSION: 'X-Api-Version',
    X_APP_VERSION: 'X-App-Version',
    X_CORRELATION_ID: 'X-Correlation-Id',
    X_APPLICATION_CALCULATOR: 'X-Application-Calculator',
    X_PLATFORM_VERSION: 'X-Platform-Version'
};

export const RequestHelper = {
    generateQuery(queryParams: StringKeyedObject<string | number>): string {
        const isEmpty = !queryParams || Object.keys(queryParams).length === 0;

        return !isEmpty
            ? `?${Object.keys(queryParams)
                .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
                .join('&')}`
            : '';
    },

    generateCorrelationId(): string {
        return `LEGO-CLIENT-${this.generateUUID()}`;
    },

    generateAppVersionHeader(): string {
        return `LEGO-CLIENT,${GlobalConfigHelper.appVersion}`;
    },

    getDefaultHeaders(): StringKeyedObject<string> {
        const trackingCli = new PricingTracking(GlobalConfigHelper.pricingApi);
        const trackingId = trackingCli.getTrackingId();
        const correlationId = this.generateCorrelationId();
        const appVersionHeader = this.generateAppVersionHeader();

        const defaultHeaders = {
            [HeaderNames.X_API_VERSION]: GlobalConfigHelper.apiVersion,
            [HeaderNames.X_CORRELATION_ID]: correlationId,
            [HeaderNames.X_APP_VERSION]: appVersionHeader,
            [HeaderNames.X_PLATFORM_VERSION]: GlobalConfigHelper.platformVersion
        };

        if (trackingId) {
            defaultHeaders[HeaderNames.X_AZIMO_UTDM] = trackingId;
        }
        return defaultHeaders;
    },

    sendRequest(
        url: string,
        method: 'GET' | 'POST' | 'DELETE',
        data: unknown,
        headers: StringKeyedObject<string>
    ): Promise<unknown> {
        const queryParamsString = method === 'GET'
            ? this.generateQuery(data as StringKeyedObject<string>)
            : '';
        const params = method === 'POST'
            ? JSON.stringify(data)
            : null;
        return new Promise((resolve, reject) => {
            const combinedHeaders = Object.assign({}, this.getDefaultHeaders(), headers);
            const xhr = new XMLHttpRequest();
            xhr.open(method, `${url}${queryParamsString}`);

            if (combinedHeaders && Object.keys(combinedHeaders).length > 0) {
                Object.keys(combinedHeaders)
                    .forEach((key) => {
                        xhr.setRequestHeader(key, combinedHeaders[key]);
                    });
            }
            xhr.onload = (): void => {
                const responseHeaders = {
                    [HeaderNames.X_CORRELATION_ID]: combinedHeaders[HeaderNames.X_CORRELATION_ID]
                };
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve({
                        data: JSON.parse(xhr.responseText),
                        headers: responseHeaders,
                        status: xhr.statusText
                    });
                } else {
                    reject({
                        error: JSON.parse(xhr.responseText),
                        headers: responseHeaders,
                        status: xhr.statusText
                    });
                }
            };
            xhr.onerror = (): void => reject(xhr.statusText);
            xhr.send(params);
        });
    },

    getRequest$(
        url: string,
        queryParams: StringKeyedObject<string | number> = {},
        headers: StringKeyedObject<string> = {}
    ): Observable<unknown> {
        const extraParams: { noTranslations?: string } = {};
        if (translationsDebug.isInlineMode) {
            extraParams.noTranslations = Date.now().toString();
        }
        return from(this.getRequest(url, { ...extraParams, ...queryParams }, headers))
            .pipe(
                catchError(error => of({ error }))
            );
    },

    getRequest<T = unknown>(
        url: string,
        queryParams: StringKeyedObject<string | number> = {},
        headers: StringKeyedObject<string> = {}
    ): Promise<T> {
        return this.sendRequest(url, 'GET', queryParams, headers);
    },

    postRequest$(
        url: string,
        queryParams: StringKeyedObject,
        headers: StringKeyedObject<string>
    ): Observable<unknown> {
        return from(this.postRequest(url, queryParams, headers))
            .pipe(
                catchError(error => of({ error }))
            );
    },

    postRequest(
        url: string,
        queryParams: StringKeyedObject,
        headers: StringKeyedObject<string>
    ): Promise<unknown> {
        return this.sendRequest(url, 'POST', queryParams, headers);
    },

    deleteRequest$(
        url: string,
        queryParams: StringKeyedObject = {},
        headers: StringKeyedObject<string> = {}
    ): Observable<unknown> {
        return from(this.deleteRequest(url, queryParams, headers))
            .pipe(
                catchError(error => of({ error }))
            );
    },

    deleteRequest(
        url: string,
        queryParams: StringKeyedObject = {},
        headers: StringKeyedObject<string> = {}
    ): Promise<unknown> {
        return this.sendRequest(url, 'DELETE', queryParams, headers);
    },

    generateUUID(): string {
        let d = new Date().getTime();

        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); // use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },

    getUrlParameter(url: string, name: string): string | undefined {
        const params = this.getAllUrlParams(url);
        return params[name];
    },

    getAllUrlParams(url: string): StringKeyedObject<string> {
        const baseDomain = `${window.location.protocol}//${window.location.host}`;
        const query = new URL(url, baseDomain).search;
        const urlSearchParams = new URLSearchParams(query);
        const paramsObject = {};
        urlSearchParams.forEach((value, key) => {
            paramsObject[key] = value;
        });
        return paramsObject;
    },

    removeNullableKeys<T>(obj: T): StringKeyedObject<NonNullable<T[keyof T]>> {
        return Object.keys(obj)
            .filter((k) => obj[k] != null)
            .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
    }
};
