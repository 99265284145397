import { CountryCode, CurrencyCode, DeliveryMethodType } from '../../enums/types';
import { AvailableDeliveryMethod, AvailablePayoutCurrency } from './calculator.types';

const DEFAULT_DELIVERY_METHODS: DeliveryMethodType[] = [
    DeliveryMethodType.ALIPAY,
    DeliveryMethodType.BANK_DEPOSIT,
    DeliveryMethodType.CASH_PICK_UP,
    DeliveryMethodType.SWIFT,
    DeliveryMethodType.MOBILE_WALLET,
    DeliveryMethodType.PHYSICAL_DELIVERY
];

const PREFERABLE_OFFICIAL_CURRENCY_METHODS = [
    DeliveryMethodType.ALIPAY,
    DeliveryMethodType.BANK_DEPOSIT,
    DeliveryMethodType.CASH_PICK_UP,
    DeliveryMethodType.MOBILE_WALLET
];

const PREFERABLE_PAYOUT_COUNTRY_METHODS = {
    NGA: DeliveryMethodType.CASH_PICK_UP,
    MAR: DeliveryMethodType.CASH_PICK_UP
};

export class CalculatorDefaultResolver {
    static isCurrencyAvailable(
        availablePayoutCurrencies: AvailablePayoutCurrency[],
        currencyIso3Code: CurrencyCode
    ): boolean {
        return availablePayoutCurrencies.some(item => item.iso3Code === currencyIso3Code);
    }

    static isDeliveryMethodAvailable(
        availableDeliveryMethods: AvailableDeliveryMethod[],
        deliveryMethod: DeliveryMethodType
    ): boolean {
        return availableDeliveryMethods.some(item => item.type === deliveryMethod);
    }

    static canOfficialCurrencyDefault(
        availablePayoutCurrencies: AvailablePayoutCurrency[],
        officialCurrency: CurrencyCode
    ): boolean {
        const officialCurrencyConfig = availablePayoutCurrencies
            .find(item => item.iso3Code === officialCurrency);
        return !!officialCurrencyConfig && officialCurrencyConfig.availableDeliveryMethods
            .some(item => PREFERABLE_OFFICIAL_CURRENCY_METHODS.includes(item.type));
    }

    static getDefaultDeliveryMethod(
        availableDeliveryMethods: AvailableDeliveryMethod[],
        previousDeliveryMethod: DeliveryMethodType,
        payoutCountry?: CountryCode
    ): DeliveryMethodType {
        const previousAvailable = CalculatorDefaultResolver
            .isDeliveryMethodAvailable(availableDeliveryMethods, previousDeliveryMethod);
        const fallbackMethod = DEFAULT_DELIVERY_METHODS.find(
            item => availableDeliveryMethods.some(dm => dm.type === item)
        ) || DeliveryMethodType.BANK_DEPOSIT;

        if (previousAvailable) {
            return previousDeliveryMethod;
        }

        if (payoutCountry) {
            return CalculatorDefaultResolver
                .getDefaultDeliveryMethodForCountry(availableDeliveryMethods, payoutCountry)
                || fallbackMethod;
        }

        return fallbackMethod;
    }

    static getDefaultDeliveryMethodForCountry(
        availableDeliveryMethods: AvailableDeliveryMethod[],
        payoutCountry: CountryCode
    ): DeliveryMethodType | undefined {
        const preferableMethod = PREFERABLE_PAYOUT_COUNTRY_METHODS[payoutCountry];
        const isPreferableMethodAvailable = availableDeliveryMethods
            .some(dm => dm.type === preferableMethod);
        if (preferableMethod && isPreferableMethodAvailable) {
            return preferableMethod;
        }
        return undefined;
    }

    static getDefaultPayoutCurrency(
        availablePayoutCurrencies: AvailablePayoutCurrency[],
        previousCurrency: CurrencyCode,
        officialCurrency: CurrencyCode
    ): CurrencyCode {
        const previousAvailable = CalculatorDefaultResolver
            .isCurrencyAvailable(availablePayoutCurrencies, previousCurrency);

        // Always default to previously selected if available
        if (previousAvailable) {
            return previousCurrency;
        }

        // Check official currency of selected payout country
        const officialCurrencyAvailable = CalculatorDefaultResolver
            .isCurrencyAvailable(availablePayoutCurrencies, officialCurrency);
        if (
            officialCurrencyAvailable
            && CalculatorDefaultResolver
                .canOfficialCurrencyDefault(availablePayoutCurrencies, officialCurrency)
        ) {
            return officialCurrency;
        }

        // Choose USD if available
        const usdAvailable = CalculatorDefaultResolver
            .isCurrencyAvailable(availablePayoutCurrencies, 'USD');

        if (usdAvailable) {
            return 'USD';
        }

        if (officialCurrencyAvailable) {
            return officialCurrency;
        }

        return availablePayoutCurrencies[0].iso3Code;
    }
}
