import { CurrencyCode, CountryCode, DeliveryMethodType } from '../../enums/types';
import {
    AvailableDeliveryMethod,
    AvailablePayoutCurrency,
    CalculatorIntention,
    CalculatorState,
    CalculatorType
} from './calculator.types';
import { LegoState } from '../../core/store.state';

export function getInitialCalculatorState(): CalculatorState {
    return {
        sendingCountry: undefined,
        sendingCurrency: undefined,
        payoutCountry: undefined,
        payoutCurrency: undefined,
        deliveryMethod: undefined,
        receivingAmount: 0,
        sendingAmount: 0,
        availablePayoutCurrencies: [],
        corridorConfigLoadingError: false,
        calculatorType: 'INDIVIDUAL',
        configLoading: false
    };
}

export function getCalculatorState(state: LegoState): CalculatorState {
    return state?.calculator || getInitialCalculatorState();
}

export function getCorridorConfigKey(state: LegoState): string | undefined {
    const calculatorState = getCalculatorState(state);
    const configArray = [
        calculatorState.sendingCountry,
        calculatorState.payoutCountry,
        calculatorState.calculatorType
    ];

    return configArray.every(item => !!item)
        ? configArray.join('.')
        : undefined;
}

export function getPricingConfigKey(state: LegoState): string | undefined {
    const calculatorState = getCalculatorState(state);
    const configArray = [
        calculatorState.sendingCountry,
        calculatorState.sendingCurrency,
        calculatorState.payoutCountry,
        calculatorState.payoutCurrency,
        calculatorState.deliveryMethod,
        calculatorState.calculatorType
    ];

    return configArray.every(item => !!item)
        ? configArray.join('.')
        : undefined;
}

export function getPricingTrackingKey(state: LegoState): string | undefined {
    const calculatorState = getCalculatorState(state);
    const configArray = [
        calculatorState.sendingCountry,
        calculatorState.sendingCurrency,
        calculatorState.payoutCountry,
        calculatorState.payoutCurrency,
        calculatorState.deliveryMethod
    ];

    return configArray.every(item => !!item)
        ? configArray.join('_')
        : undefined;
}

export function getIntentionData(state: LegoState): CalculatorIntention | undefined {
    const calculatorState = getCalculatorState(state);
    if (calculatorState) {
        return {
            sendingCountry: calculatorState.sendingCountry,
            sendingCurrency: calculatorState.sendingCurrency,
            payoutCountry: calculatorState.payoutCountry,
            payoutCurrency: calculatorState.payoutCurrency,
            sendingAmount: calculatorState.sendingAmount
        };
    }
    return undefined;
}

export function getPayoutCountry(state: LegoState): CountryCode | undefined {
    return state?.calculator?.payoutCountry;
}

export function getDeliveryMethod(state: LegoState): DeliveryMethodType | undefined {
    return state?.calculator?.deliveryMethod;
}

export function isConfigLoading(state: LegoState): boolean {
    return getCalculatorState(state).configLoading;
}

export function getSendingCurrency(state: LegoState): CurrencyCode | undefined {
    return getCalculatorState(state).sendingCurrency;
}

export function getPayoutCurrency(state: LegoState): CurrencyCode | undefined {
    return getCalculatorState(state).payoutCurrency;
}

export function hasCorridorConfigError(state: LegoState): boolean {
    return getCalculatorState(state).corridorConfigLoadingError;
}

export function getCalculatorType(state: LegoState): CalculatorType {
    return getCalculatorState(state).calculatorType;
}

export function getCurrentPayoutCurrencyDetails(state: LegoState): AvailablePayoutCurrency | undefined {
    const calculatorState = getCalculatorState(state);
    if (calculatorState.availablePayoutCurrencies && calculatorState.payoutCurrency) {
        return calculatorState.availablePayoutCurrencies
            .find(item => item.iso3Code === calculatorState.payoutCurrency);
    }
    return undefined;
}

export function getCurrentDeliveryMethodDetails(state: LegoState): AvailableDeliveryMethod | undefined {
    const deliveryMethod = getDeliveryMethod(state);
    const currentPayoutCurrency = getCurrentPayoutCurrencyDetails(state);
    if (currentPayoutCurrency && currentPayoutCurrency.availableDeliveryMethods && deliveryMethod) {
        return currentPayoutCurrency.availableDeliveryMethods
            .find(item => item.type === deliveryMethod);
    }
    return undefined;
}

export function getCurrentDeliveryTime(calculatorState: LegoState): string | undefined {
    const currentDeliveryMethod = getCurrentDeliveryMethodDetails(calculatorState);
    if (currentDeliveryMethod && currentDeliveryMethod.estimatedDeliveryTime) {
        return currentDeliveryMethod.estimatedDeliveryTime;
    }
    return undefined;
}

export function getSendingAmount(state: LegoState): number | undefined {
    return getCalculatorState(state)?.sendingAmount;
}

export function getReceivingCountry(state: LegoState): CountryCode | undefined {
    return getCalculatorState(state)?.payoutCountry;
}

export function getReceivingAmount(state: LegoState): number | undefined {
    return getCalculatorState(state)?.receivingAmount;
}
