import { browser } from '../browser/browser';
import { GlobalConfigHelper } from '../global-config/global-config';

export enum EventCategory {
    CUSTOM = 'Custom event',
    CUSTOM_PAGE_EVENT = 'Custom page event',
    PERFORMANCE = 'Performance event',
    PWA = 'PWA event',
    CLICK = 'Click event'
}

export enum EventName {
    BUTTON_CLICK = 'BUTTON_CLICK',
    LINK_CLICK = 'LINK_CLICK',
    FORM_SUBMIT = 'FORM_SUBMIT',
    DROPDOWN_CLICK = 'DROPDOWN_CLICK',
    DROPDOWN_OPTION_SELECT = 'DROPDOWN_OPTION_SELECT',
    PAGE_SEEN = 'PAGE_SEEN',
    PRODUCT_VIEWED = 'Product Viewed',
    EXPERIMENT_APPLIED = 'Experiment Applied',
    LAUNCHED_FROM_HOME_SCREEN = 'LAUNCHED FROM HOME SCREEN',
    NETWORK_OFFLINE = 'NETWORK OFFLINE EVENT',
    PWA_APP_ADDED_TO_HOME_SCREEN = 'PWA APP ADDED TO HOME SCREEN',
    RECEIVING_PAYMENTS_POP_UP = 'RECEIVING PAYMENTS POP UP DISPLAYED',
    RECEIVING_PAYMENTS_STRIP_COMPONENT = 'RECEIVING PAYMENTS STRIP COMPONENT DISPLAYED'
}

export const BASE_EVENT = {
    app_version: GlobalConfigHelper.appVersion,
    client_type: 'LEGO',
    browser_name: browser.parser.getBrowserName(),
    browser_version: browser.parser.getBrowserVersion(),
    os_name: browser.parser.getOSName(),
    os_version: browser.parser.getOSVersion(),
    device: browser.parser.getPlatformType(),
    device_vendor: browser.parser.getPlatform().vendor
};

export const CUSTOM_EVENT = { ...BASE_EVENT, category: EventCategory.CUSTOM };
export const PERFORMANCE_EVENT = { ...BASE_EVENT, category: EventCategory.PERFORMANCE };
export const PWA_EVENT = { ...BASE_EVENT, category: EventCategory.PWA };
export const BUTTON_CLICK_EVENT = { ...BASE_EVENT, category: EventCategory.CLICK };
export const LINK_CLICK_EVENT = { ...BASE_EVENT, category: EventCategory.CLICK };
export const DROPDOWN_CLICK_EVENT = { ...BASE_EVENT, category: EventCategory.CLICK };
export const DROPDOWN_OPTION_SELECT_EVENT = { ...BASE_EVENT, category: EventCategory.CLICK };
export const PAGE_SEEN_EVENT = { ...BASE_EVENT, category: EventCategory.CUSTOM_PAGE_EVENT };
