import { TypedAction } from '../../types';

export enum LangSwitcherActionTypes {
    TOGGLE = '[LangSwitcher] Toggle',
    OPEN = '[LangSwitcher] Open',
    CLOSE = '[LangSwitcher] Close'
}

export class LangSwitcherToggleAction implements TypedAction<LangSwitcherActionTypes> {
    readonly type = LangSwitcherActionTypes.TOGGLE;
}

export class LangSwitcherOpenAction implements TypedAction<LangSwitcherActionTypes> {
    readonly type = LangSwitcherActionTypes.OPEN;
}

export class LangSwitcherCloseAction implements TypedAction<LangSwitcherActionTypes> {
    readonly type = LangSwitcherActionTypes.CLOSE;
}

export type LangSwitcherActions =
    | LangSwitcherToggleAction
    | LangSwitcherOpenAction
    | LangSwitcherCloseAction;
