import { getCountriesInitialState } from '../modules/countries/countries.state';
import { getInitialCalculatorState } from '../modules/calculator/calculator.state';
import { getInitialLangSwitcherState } from '../modules/lang-switcher/lang-switcher.state';
import { getRateAlertInitialState } from '../modules/rate-alert/rate-alert.state';
import { getInitialPricingState } from '../modules/pricing/pricing.state';
import { PricingState } from '../modules/pricing/pricing.types';
import { CalculatorState } from '../modules/calculator/calculator.types';
import { LangSwitcherState } from '../modules/lang-switcher/lang-switcher.types';
import { CountriesState } from '../modules/countries/countries.types';
import { RateAlertState } from '../modules/rate-alert/rate-alert.types';
import { StringKeyedObject } from '../types';
import { partnerLogosInitialState } from '../modules/partner-logos/partner-logos.state';
import { PartnerLogoState } from '../modules/partner-logos/partner-logos.types';

export type LegoState = {
    langSwitcher: LangSwitcherState,
    calculator: CalculatorState,
    countries: CountriesState,
    pricing: PricingState,
    partnerLogos: StringKeyedObject<PartnerLogoState>,
    rateAlert: RateAlertState
}

export const initialState: LegoState = {
    langSwitcher: getInitialLangSwitcherState(),
    calculator: getInitialCalculatorState(),
    countries: getCountriesInitialState(),
    pricing: getInitialPricingState(),
    rateAlert: getRateAlertInitialState(),
    partnerLogos: partnerLogosInitialState()
};
