import { TypedAction } from '../../types';
import { CalculatorType } from './calculator.types';
import { CountryCode, CurrencyCode, DeliveryMethodType } from '../../enums/types';

export enum CalculatorActionTypes {
    SET_DELIVERY_METHOD = '[Calculator] Set delivery method',
    SET_PAYOUT_CURRENCY = '[Calculator] Set payout currency',
    SET_SENDING_CURRENCY = '[Calculator] Set sending currency',
    SET_SENDING_AMOUNT = '[Calculator] Set sending amount',
    SET_RECEIVING_AMOUNT = '[Calculator] Set receiving amount',
    SET_CALCULATOR_TYPE = '[Calculator] Set calculator type'
}

export class CalculatorSetCalculatorType implements TypedAction<CalculatorActionTypes> {
    readonly type = CalculatorActionTypes.SET_CALCULATOR_TYPE;

    constructor(public payload: {
        type: CalculatorType
    }) {}
}

export class CalculatorSetSendingCurrencyAction implements TypedAction<CalculatorActionTypes> {
    readonly type = CalculatorActionTypes.SET_SENDING_CURRENCY;

    constructor(public payload: {
        currencyCode: CurrencyCode,
        countryCode: CountryCode
    }) {}
}

export class CalculatorSetPayoutCurrency implements TypedAction<CalculatorActionTypes> {
    readonly type = CalculatorActionTypes.SET_PAYOUT_CURRENCY;

    constructor(public payload: {
        payoutCurrency: CurrencyCode
    }) {}
}

export class CalculatorSetDeliveryMethod implements TypedAction<CalculatorActionTypes> {
    readonly type = CalculatorActionTypes.SET_DELIVERY_METHOD;
    constructor(public payload: {
        deliveryMethod: DeliveryMethodType
    }) {}
}

export class CalculatorSetSendingAmount implements TypedAction<CalculatorActionTypes> {
    readonly type = CalculatorActionTypes.SET_SENDING_AMOUNT;

    constructor(public payload: {
        value: number
    }) {}
}

export class CalculatorSetReceivingAmount implements TypedAction<CalculatorActionTypes> {
    readonly type = CalculatorActionTypes.SET_RECEIVING_AMOUNT;

    constructor(public payload: {
        value: number
    }) {}
}

export type CalculatorActions =
    | CalculatorSetCalculatorType
    | CalculatorSetSendingCurrencyAction
    | CalculatorSetPayoutCurrency
    | CalculatorSetDeliveryMethod
    | CalculatorSetSendingAmount
    | CalculatorSetReceivingAmount;
