import {
    RateAlert,
    RateAlertState,
    RateAlertSubscription,
    RateAlertUnsubscriptionStatus,
    RateCountries,
    RateCountryConfig,
    RatePricing,
    RatePricingDetails
} from './rate-alert.types';
import { LegoState } from '../../core/store.state';
import { DeliveryMethodType } from '../../enums/types';

export function getRateAlertInitialState(): RateAlertState {
    return {
        defaultConfig: {
            sendingCountryConfig: undefined,
            receivingCountryConfig: undefined
        },
        countries: {
            sendingCountries: [],
            receivingCountries: [],
            loading: false
        },
        pricing: {
            details: undefined,
            loading: false,
            error: false
        },
        subscription: {
            sendingCountryConfig: undefined,
            receivingCountryConfig: undefined,
            result: undefined,
            loading: false
        },
        unsubscription: {
            details: {
                alert: undefined,
                loading: false,
                error: ''
            },
            status: {
                loading: false,
                error: false
            }
        }
    };
}

export function getRateCountriesState(state: LegoState): RateCountries {
    return state.rateAlert.countries;
}

export function getSubscriptionState(state: LegoState): RateAlertSubscription | undefined {
    return state.rateAlert.subscription;
}

export function getRatePricingState(state: LegoState): RatePricing {
    return state.rateAlert.pricing;
}

export function getRateAlertReceivingCountries(state: LegoState): RateCountryConfig[] {
    const countriesState = getRateCountriesState(state);
    return countriesState.receivingCountries;
}

export function getRateAlertSendingCountries(state: LegoState): RateCountryConfig[] {
    const countriesState = getRateCountriesState(state);
    return countriesState.sendingCountries;
}

export function getRateAlertSendingCountryConfig(state: LegoState): RateCountryConfig | undefined {
    const subscriptionState = getSubscriptionState(state);
    return subscriptionState?.sendingCountryConfig;
}

export function getRateAlertReceivingCountryConfig(state: LegoState): RateCountryConfig | undefined {
    const subscriptionState = getSubscriptionState(state);
    return subscriptionState?.receivingCountryConfig;
}

export function getRateAlertDeliveryMethod(state: LegoState): DeliveryMethodType | undefined {
    const pricingState = getRatePricingState(state);
    return pricingState.details?.deliveryMethod as DeliveryMethodType;
}

export function getRateAlertPricingDetails(state: LegoState): RatePricingDetails | undefined {
    const pricingState = getRatePricingState(state);
    return pricingState.details;
}

export function getRateAlertPricingDetailsError(state: LegoState): boolean {
    const pricingState = getRatePricingState(state);
    return pricingState.error;
}

export function getRateAlertPricingLoading(state: LegoState): boolean {
    const pricingState = getRatePricingState(state);
    return pricingState.loading;
}

export function getRateAlertUnsubscribeDetails(state: LegoState): RateAlert | undefined {
    return state.rateAlert.unsubscription.details.alert;
}

export function getRateAlertUnsubscribeDetailsError(state: LegoState): string {
    return state.rateAlert.unsubscription.details.error;
}

export function getRateAlertUnsubscribeDetailsLoading(state: LegoState): boolean {
    return state.rateAlert.unsubscription.details.loading;
}

export function getRateAlertUnsubscribeStatus(state: LegoState): RateAlertUnsubscriptionStatus {
    return state.rateAlert.unsubscription.status;
}

export function getRateAlertCorridor(
    state: LegoState
): { sendingCountryConfig: RateCountryConfig | undefined, receivingCountryConfig: RateCountryConfig | undefined } {
    const sendingCountryConfig = getRateAlertSendingCountryConfig(state);
    const receivingCountryConfig = getRateAlertReceivingCountryConfig(state);

    return { sendingCountryConfig, receivingCountryConfig };
}
