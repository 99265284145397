import { PartnerLogo } from './partner-logos.types';
import { TypedAction } from '../../types';
import { CountryCode, DeliveryMethodType } from '../../enums/types';

export enum PartnerLogosActionTypes {
  LOAD = '[Partner Logos] Load',
  LOAD_ERROR = '[Partner Logos] Load Error',
  LOAD_SUCCESS = '[Partner Logos] Load Success'
}

export class PartnerLogosLoadAction implements TypedAction<PartnerLogosActionTypes> {
    readonly type = PartnerLogosActionTypes.LOAD;

    constructor(public payload: {
        payoutCountry: CountryCode;
        deliveryMethod: DeliveryMethodType;
    }) {}
}

export class PartnerLogosLoadSuccessAction implements TypedAction<PartnerLogosActionTypes> {
    readonly type = PartnerLogosActionTypes.LOAD_SUCCESS;

    constructor(public payload: {
        payoutCountry: CountryCode;
        deliveryMethod: DeliveryMethodType;
        logos: PartnerLogo[];
    }) {}
}

export class PartnerLogosLoadErrorAction implements TypedAction<PartnerLogosActionTypes> {
    readonly type = PartnerLogosActionTypes.LOAD_ERROR;

    constructor(public payload: {
        payoutCountry: CountryCode;
        deliveryMethod: DeliveryMethodType;
    }) {}
}

export type PartnerLogosActions =
  PartnerLogosLoadAction |
  PartnerLogosLoadSuccessAction |
  PartnerLogosLoadErrorAction;
