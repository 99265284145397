import { TypedAction } from '../../types';

export enum CurrencyFieldActionTypes {
    RECEIVING_INPUT = '[Currency Field] Receiving Amount Input',
    SENDING_INPUT = '[Currency Field] Sending Amount Input',
    INPUT_BLUR = '[Currency Field] Amount Input Blur',
    APPLY_AMOUNT_MULTIPLIER = '[Currency Field] Apply Amount Multiplier'
}

export class ReceivingInputCurrencyFieldAction implements TypedAction<CurrencyFieldActionTypes> {
    readonly type = CurrencyFieldActionTypes.RECEIVING_INPUT;

    constructor(public payload: {
        value: number
    }) {}
}

export class SendingInputCurrencyFieldAction implements TypedAction<CurrencyFieldActionTypes> {
    readonly type = CurrencyFieldActionTypes.SENDING_INPUT;

    constructor(public payload: {
        value: number
    }) {}
}

export class InputCurrencyFieldBlurAction implements TypedAction<CurrencyFieldActionTypes> {
    readonly type = CurrencyFieldActionTypes.INPUT_BLUR;
}

export class ApplyAmountMultiplierAction implements TypedAction<CurrencyFieldActionTypes> {
    readonly type = CurrencyFieldActionTypes.APPLY_AMOUNT_MULTIPLIER;
}

export type CurrencyFieldActions =
    | ReceivingInputCurrencyFieldAction
    | SendingInputCurrencyFieldAction
    | InputCurrencyFieldBlurAction
    | ApplyAmountMultiplierAction;
