import { StringKeyedObject, TypedAction } from '../../types';
import { RateAlert, RateCountryConfig, RatePricingDetails } from './rate-alert.types';
import { CountryCode, CurrencyCode } from '../../enums/types';

export enum RateAlertActionTypes {
    SENDING_COUNTRIES_LOAD = '[Rate Alert] Sending Countries Load',
    SENDING_COUNTRIES_LOAD_SUCCESS = '[Rate Alert] Sending Countries Load Success',
    SENDING_COUNTRIES_LOAD_ERROR = '[Rate Alert] Sending Countries Load Error',
    RECEIVING_COUNTRIES_LOAD = '[Rate Alert] Receiving Countries Load',
    RECEIVING_COUNTRIES_LOAD_SUCCESS = '[Rate Alert] Receiving Countries Load Success',
    RECEIVING_COUNTRIES_LOAD_ERROR = '[Rate Alert] Receiving Countries Load Error',
    PRICING_LOAD = '[Rate Alert] Pricing Load',
    PRICING_LOAD_SUCCESS = '[Rate Alert] Pricing Load Success',
    PRICING_LOAD_ERROR = '[Rate Alert] Pricing Load Success Error',
    PRICING_LOAD_CLEAN_ERROR = '[Rate Alert] Pricing Load Clean Error',
    SELECT_SENDING_COUNTRY = '[Rate Alert] Select sending country',
    SELECT_RECEIVING_COUNTRY = '[Rate Alert] Select receiving country',

    SUBSCRIBE = '[Rate Alert] Subscribe',
    SUBSCRIBE_SUCCESS = '[Rate Alert] Subscribe Success',
    SUBSCRIBE_ERROR = '[Rate Alert] Subscribe Error',

    RATE_ALERT_DETAILS_LOAD = '[Rate Alert] Details Load',
    RATE_ALERT_DETAILS_LOAD_SUCCESS = '[Rate Alert] Details Load Success',
    RATE_ALERT_DETAILS_LOAD_ERROR = '[Rate Alert] Details Load Error',

    RATE_ALERT_UNSUBSCRIBE = '[Rate Alert] Unsubscribe',
    RATE_ALERT_UNSUBSCRIBE_SUCCESS = '[Rate Alert] Unsubscribe Success',
    RATE_ALERT_UNSUBSCRIBE_ERROR = '[Rate Alert] Unsubscribe Error',

    RATE_ALERT_SET_DEFAULT_CONFIG = '[Rate Alert] Set default config'
}

export class RateAlertSendingCountriesLoadAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SENDING_COUNTRIES_LOAD;
}

export class RateAlertSendingCountriesLoadSuccessAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SENDING_COUNTRIES_LOAD_SUCCESS;

    constructor(public payload: RateCountryConfig[]) {}
}

export class RateAlertSendingCountriesLoadErrorAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SENDING_COUNTRIES_LOAD_ERROR;
}

export class RateAlertReceivingCountriesLoadAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD;

    constructor(public payload: {
        sendingCountry: CountryCode
    }) {}
}

export class RateAlertReceivingCountriesLoadSuccessAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD_SUCCESS;

    constructor(public payload: RateCountryConfig[]) {}
}

export class RateAlertReceivingCountriesLoadErrorAction {
    readonly type = RateAlertActionTypes.RECEIVING_COUNTRIES_LOAD_ERROR;
}

export class RateAlertPricingLoadAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.PRICING_LOAD;

    constructor(public payload: {
        sendingCountry: CountryCode,
        receivingCountry: CountryCode,
        sendingCurrency: CurrencyCode,
        receivingCurrency: CurrencyCode
    }) {}
}

export class RateAlertPricingLoadSuccessAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.PRICING_LOAD_SUCCESS;

    constructor(public payload: RatePricingDetails) {}
}

export class RateAlertPricingLoadErrorAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.PRICING_LOAD_ERROR;
}

export class RateAlertSelectSendingCountryAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SELECT_SENDING_COUNTRY;
    constructor(public payload: CountryCode) {}
}

export class RateAlertSelectReceivingCountryAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SELECT_RECEIVING_COUNTRY;

    constructor(public payload: CountryCode) {}
}

export class RateAlertSubscribeAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SUBSCRIBE;
    constructor(public payload: {
        alerts: Partial<RateAlert>[]
    }) {}
}

export class RateAlertSubscribeSuccessAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SUBSCRIBE_SUCCESS;
    constructor(public payload: unknown) {}
}

export class RateAlertSubscribeErrorAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.SUBSCRIBE_ERROR;
    constructor(public payload: unknown) {}
}

export class RateAlertDetailsLoadAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_DETAILS_LOAD;

    constructor(public payload: string) {}
}

export class RateAlertDetailsLoadSuccessAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_DETAILS_LOAD_SUCCESS;
    constructor(public payload: { alert: RateAlert }) {}
}

export class RateAlertDetailsLoadErrorAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_DETAILS_LOAD_ERROR;
    payload?: string;

    constructor(payload?: { status: string }) {
        this.payload = payload?.status;
    }
}

export class RateAlertUnsubscribeAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE;

    constructor(public payload: {
        rateAlertUuid: string;
    }) {}
}

export class RateAlertUnsubscribeSuccessAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE_SUCCESS;
}

export class RateAlertUnsubscribeErrorAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_UNSUBSCRIBE_ERROR;
}

export class RateAlertSetDefaultConfigAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.RATE_ALERT_SET_DEFAULT_CONFIG;
    constructor(public payload: {
        config: StringKeyedObject
    }) {}
}

export class RateAlertPricingLoadCleanErrorAction implements TypedAction<RateAlertActionTypes> {
    readonly type = RateAlertActionTypes.PRICING_LOAD_CLEAN_ERROR;
}

export type RateAlertActions =
    RateAlertSendingCountriesLoadAction
    | RateAlertSendingCountriesLoadSuccessAction
    | RateAlertSendingCountriesLoadErrorAction
    | RateAlertReceivingCountriesLoadAction
    | RateAlertReceivingCountriesLoadSuccessAction
    | RateAlertReceivingCountriesLoadErrorAction
    | RateAlertPricingLoadAction
    | RateAlertPricingLoadSuccessAction
    | RateAlertPricingLoadErrorAction
    | RateAlertSelectSendingCountryAction
    | RateAlertSelectReceivingCountryAction
    | RateAlertSubscribeAction
    | RateAlertSubscribeSuccessAction
    | RateAlertSubscribeErrorAction
    | RateAlertDetailsLoadAction
    | RateAlertDetailsLoadSuccessAction
    | RateAlertDetailsLoadErrorAction
    | RateAlertUnsubscribeAction
    | RateAlertUnsubscribeSuccessAction
    | RateAlertUnsubscribeErrorAction
    | RateAlertSetDefaultConfigAction
    | RateAlertPricingLoadCleanErrorAction;
