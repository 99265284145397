import { produce } from 'immer';

import { getInitialPricingState } from './pricing.state';
import { PricingActionTypes } from './pricing.actions';

export const pricingReducer = produce((
    draft = getInitialPricingState(),
    action
) => {
    switch (action.type) {
        case PricingActionTypes.LOAD: {
            return {
                ...draft,
                loading: true
            };
        }

        case PricingActionTypes.LOAD_SUCCESS: {
            return {
                ...draft,
                pricingVariants: action.payload.pricingVariants,
                loading: false
            };
        }

        case PricingActionTypes.LOAD_ERROR:
            return {
                ...draft,
                loading: false
            };

        case PricingActionTypes.SET_CURRENT_VARIANT: {
            return {
                ...draft,
                currentVariantId: action.payload.currentId
            };
        }

        default:
            return draft;
    }
});
